import { css } from '@linaria/core';
import { defaultColors } from './colors';
import BackgroundColorStyles from './overrides/BackgroundColorStyles';
import BorderStyles from './overrides/BorderStyles';
import ButtonStyles from './overrides/ButtonStyles';
import TypographyStyles from './overrides/TypographyStyles';
import { theme } from './theme';

export const globals = css`
  :global() {
    @font-face {
      font-family: 'Antenna';
      src: url('../assets/fonts/antenna/AntennaPurina-ExtraLight.otf') format('opentype');
      font-display: swap;
      font-weight: 200;
    }

    @font-face {
      font-family: 'Antenna';
      src: url('../assets/fonts/antenna/AntennaPurina-Light.otf') format('opentype');
      font-display: swap;
      font-weight: 300;
    }

    @font-face {
      font-family: 'Antenna';
      src: url('../assets/fonts/antenna/AntennaPurina-Regular.otf') format('opentype');
      font-display: swap;
      font-weight: 400;
    }

    @font-face {
      font-family: 'Antenna';
      src: url('../assets/fonts/antenna/AntennaPurina-Medium.otf') format('opentype');
      font-display: swap;
      font-weight: 500;
    }

    @font-face {
      font-family: 'Antenna';
      src: url('../assets/fonts/antenna/AntennaPurina-Bold.otf') format('opentype');
      font-display: swap;
      font-weight: 600;
    }

    * {
      scroll-margin-top: 95px;

      @media (min-width: 992px) {
        scroll-margin-top: 143px;
      }
    }

    html {
      box-sizing: border-box;
    }

    body {
      font-family: 'Antenna';
      font-size: 1.125rem;

      ::-webkit-scrollbar {
        background-color: ${theme.common.common.white};
        width: 16px;
      }

      /* background of the scrollbar except button or resizer */
      ::-webkit-scrollbar-track {
        background-color: ${theme.common.common.white};
        box-shadow: inset 0 0 6px ${theme.common.gray[300]};
      }
      ::-webkit-scrollbar-track:hover {
        background-color: #f4f4f4;
      }

      /* scrollbar itself */
      ::-webkit-scrollbar-thumb {
        background-color: #babac0;
        border-radius: 16px;
        border: 5px solid ${theme.common.common.white};
      }
      ::-webkit-scrollbar-thumb:hover {
        background-color: #a0a0a5;
        border: 4px solid #f4f4f4;
      }

      /* set button(top and bottom of the scrollbar) */
      ::-webkit-scrollbar-button {
        display: none;
      }
    }

    .sticky-top {
      z-index: 1000 !important;
    }

    .bg-dark {
      background-color: ${defaultColors.dark} !important;
    }

    .bg-light {
      background-color: ${defaultColors.light} !important;
    }

    body,
    .text-dark,
    .text-body {
      color: ${defaultColors.dark} !important;
    }

    .text-primary {
      color: ${defaultColors.primary} !important;
    }

    *,
    *:before,
    *:after {
      box-sizing: inherit;
    }

    body {
      margin: 0;
    }

    .btn {
      display: inline-flex;
      align-items: center;
      transition: background-color 0.3s linear;

      &:focus-visible {
        outline: currentColor solid 1px;
      }

      > svg {
        position: relative;
      }

      &.action-right {
        > svg {
          position: relative;
        }

        &:hover,
        &:focus {
          > svg {
            left: 0.25rem;
          }
        }
      }
    }

    .opacity-hover {
      &:hover {
        opacity: 0 !important;
      }
    }
    .opacity-25-hover {
      &:hover {
        opacity: 0.25 !important;
      }
    }
    .opacity-50-hover {
      &:hover {
        opacity: 0.5 !important;
      }
    }
    .opacity-75-hover {
      &:hover {
        opacity: 0.75 !important;
      }
    }
    .opacity-100-hover {
      &:hover {
        opacity: 1 !important;
      }
    }

    .btn:focus,
    .btn:active:focus {
      box-shadow: none;
    }

    ${TypographyStyles}

    ${BackgroundColorStyles}

    ${ButtonStyles}

    ${BorderStyles}
    
    // Tabs
    .nav-tabs {
      border: none;
      position: relative;
      .nav-item {
        margin-right: 0.375rem;
        &:last-child {
          margin: 0;
        }
        .nav-link {
          background-color: #f6f7fb;
          border-color: #f6f7fb;
          border-width: 0 0 2px 0;
          margin: 0;
          &.active {
            border-color: #000000;
          }
        }
      }
      &::before {
        background-color: #e4e9f4;
        bottom: 0;
        content: '';
        height: 2px;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: -1;
      }
    }

    // Accordions
    .accordion-button[aria-expanded='true'] {
      color: ${defaultColors.dark};
      background-color: ${theme.neutral.background.default};
    }
    .accordion-button:focus {
      border-color: ${theme.neutral.background.default};
      box-shadow: 0 0 0 0.25rem #c3d3e5;
    }
    .accordion-item {
      border-radius: 0 !important;
      border-inline: 0 !important;
    }
    .accordion-button {
      justify-content: space-between;
    }
    .accordion-button::after {
      margin-left: 1rem;
    }
    .form-check label {
      font-size: 16px;
      font-weight: 400;
      letter-spacing: inherit;
      text-transform: none;
    }
    .form-check-input:checked {
      background-color: ${defaultColors.dark};
      border-color: ${defaultColors.dark};
    }

    // Font sizes
    .fs-large {
      font-size: 1.25rem;
    }
    .fs-x-large {
      font-size: 1.375rem;
    }

    .pagination-lg .page-link {
      font-size: 1rem;
      color: #2553f8;
      padding: 0.5rem 0.75rem;

      @media screen and (min-width: 480px) {
        padding: 0.75rem 1.25rem;
      }
    }

    .page-item.active .page-link {
      background-color: #e3e7f2;
      border-color: #e3e7f2;
      color: #2b2b2b;
    }

    .sr-only {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      border: 0;
    }

    @media (min-width: 1680px) {
      .container,
      .container-lg,
      .container-md,
      .container-sm,
      .container-xl,
      .container-xxl {
        max-width: 1600px;
      }
    }
  }
`;
