import { mdiFilterOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { styled } from '@linaria/react';
import React, { useContext } from 'react';
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CMS_THEME } from '../../common/enums';
import { theme } from '../../theme/theme';
import { getActiveFacets } from '../../utils/helpers';
import SkipLink from '../screen_reader/SkipLink';
import SearchActiveFacetList from './SearchActiveFacetList';
import { SearchListingContext } from './SearchListingContext';

type Props = {
  title?: string;
  onFacetRemove: (event: React.ChangeEvent<HTMLInputElement>) => void;
  hideFilters?: boolean;
};

const StyledFilterIcon = styled(Icon)`
  height: 40px;
  width: 40px;
  background-color: #edf0f8;
  border-radius: 50%;
  padding: 10px;
`;

const StyledButton = styled.button`
  background: none;
  border: none;
  border-radius: 50%;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  &:focus-visible {
    outline: 5px auto -webkit-focus-ring-color;
  }
`;

const ResultCountContainer = styled(Col)`
  display: flex;
  align-items: center;

  & > * + * {
    border-left: 1px solid #ced4e6;
  }
`;

const SearchHeader = function ({ hideFilters = false, ...props }: Props) {
  const context = useContext(SearchListingContext);

  const { t } = useTranslation();
  if (!context) {
    throw new Error(
      'Child components of SearchListing cannot be rendered outside the SearchListing component!'
    );
  }

  const { data, setFacetModalActive } = context || {};
  const { pager, facets = {} } = data || {};
  const { title = t('Search'), onFacetRemove } = props;

  const originalResultWithCount = t('resultwithcount', { count: pager?.total_items ?? 0 });
  let resultWithCount = originalResultWithCount;

  if (originalResultWithCount === 'resultwithcount' && pager) {
    resultWithCount = `${pager.total_items.toLocaleString()} ${
      pager.total_items === 1 ? 'result' : 'results'
    } `;
  }

  const activeFacets = getActiveFacets(facets);

  return (
    <>
      <Col as="h2" md={3} xs={6} className="m-0 typography_h2">
        {title}
      </Col>
      <ResultCountContainer className="justify-content-end justify-content-lg-start">
        {pager && (
          <span className="flex-shrink-0" aria-live="assertive">
            {resultWithCount}
          </span>
        )}
        {!hideFilters && (
          <>
            {data?.search_results?.length && data.search_results.length > 0 && (
              <SkipLink href="#search-results" className="mx-1">
                {t('Skip to results')}.
              </SkipLink>
            )}
            {activeFacets.length > 0 && (
              <SearchActiveFacetList
                className="d-none d-lg-inline-flex"
                activeFacets={activeFacets}
                onFacetRemove={onFacetRemove}
              />
            )}
          </>
        )}
      </ResultCountContainer>
      {!hideFilters && (
        <Col className="filter-toggle d-block d-lg-none py-0" xs="auto">
          <StyledButton
            type="button"
            aria-label={t('Filter results')}
            style={{
              color: theme[CMS_THEME.NEUTRAL].text.default,
            }}
            onClick={() => setFacetModalActive(true)}
          >
            <StyledFilterIcon path={mdiFilterOutline} />
          </StyledButton>
        </Col>
      )}
    </>
  );
};

SearchHeader.displayName = 'SearchListing.SearchHeader';

export default SearchHeader;
