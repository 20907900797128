import { AxiosError } from 'axios';
import { PageProps, graphql } from 'gatsby';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import React from 'react';
import { Container } from 'react-bootstrap';
import ParagraphSearchForm from 'src/components/paragraphs/ParagraphSearchForm';
import { getCmsComponent } from 'src/utils/cmsComponentHelpers';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import RichText from '../../components/rich_text/RichText';
import {
  getHelmetPropsFromMetatagProps,
  getJsonFromMetatagProps,
} from '../../utils/metatagHelpers';
import EventListingBody from '../components/EventListingBody';
import { getSearchData } from './helpers';
import { EventSD } from './types/types';

interface DataProps {
  node: IEventListing;
  translations: Translations;
}

const EventListingTemplate: React.FC<PageProps<DataProps, any, any, EventSD>> = function ({
  data: { node, translations },
  location,
  serverData,
}) {
  const { components, subnav } = node.relationships;
  const helmetProps = getHelmetPropsFromMetatagProps(node.metatag);
  const schemaMarkup = getJsonFromMetatagProps(node.metatag);

  return (
    <Layout
      node={node}
      metaData={node.metatag}
      language={node.langcode}
      translations={translations}
    >
      <Seo {...helmetProps} schemaMarkup={schemaMarkup} />
      {subnav && getCmsComponent({ node: subnav, index: 0 })}
      <Container fluid className="bg-gray-100 py-5 text-center">
        <h1>{node.title}</h1>
        {node?.body?.processed && (
          <RichText className="text-center py-4" body={node.body.processed} />
        )}

        {components?.map((component, index) => {
          return (
            <React.Fragment key={component.id}>
              {getCmsComponent({ node: component, index })}
            </React.Fragment>
          );
        })}
        <Container className="mt-5">
          <div className="mx-lg-5">
            <ParagraphSearchForm
              props={{
                showSearch: true,
                showMenu: false,
                showCancel: false,
                setShowSearch: () => true,
                selectedSearchCategory: 'events',
                is_listing_page: true,
              }}
            />
          </div>
        </Container>
      </Container>
      <AnalyticsPoint
        type="module"
        label="Events section"
        typeLabel="event_listing"
        as={Container}
        fluid
      >
        <EventListingBody
          data={serverData.eventData}
          serverData={serverData.eventData}
          error={serverData.error}
          contextualFilters={serverData.contextualFilters}
          location={location}
        />
      </AnalyticsPoint>
    </Layout>
  );
};

export const getServerData = async (props: any) => {
  try {
    const eventData = await getSearchData('events', 'event', props.pageContext, {
      sort_by: 'latest',
      ...props.query,
    });
    return {
      props: {
        eventData: eventData.data,
        contextualFilters: eventData.contextualFilters,
        error: null,
      },
    };
  } catch (e) {
    return {
      props: {
        error: (e as AxiosError).response,
        eventData: null,
      },
    };
  }
};

export const query = graphql`
  query ($id: String!, $nid: Int!, $language: String!) {
    node: nodeEventListingPage(id: { eq: $id }, langcode: { eq: $language }) {
      internal {
        type
      }
      id
      title
      path {
        alias
      }
      body {
        processed
      }
      langcode
      ...Metatag
      relationships {
        event_duration: field_event_duration {
          drupal_internal__tid
        }
        type: field_event_type {
          drupal_internal__tid
        }
        series: field_event_series {
          drupal_internal__tid
        }
        topic: field_event_topic {
          drupal_internal__tid
        }
        upcoming_past: field_event_upcoming_or_past {
          drupal_internal__tid
        }
        components: field_storage {
          type: __typename
          ...StorageArticleCards
          ...StorageImageContent
          ...StorageHeroCard
          ...StorageManualCards
          ...StorageHighlights
          ...StorageRichText
          ...StoragePageTitle
          ...StorageBanner
          ...StorageBrandGrid
          ...StorageFaqSection
          ...StorageUnfilteredHtml
          ...StorageSubnav
          ...StorageCardGrid
          ...StorageCategoryCards
          ...StorageContactCards
          ...StorageVideo
        }
      }
    }
    translations: allNodeEventListingPage(
      filter: { drupal_internal__nid: { eq: $nid }, langcode: { ne: $language } }
    ) {
      edges {
        node {
          langcode
          path {
            alias
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default EventListingTemplate;
