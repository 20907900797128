import { css } from '@linaria/core';
import { styled } from '@linaria/react';
import React, { useEffect, useRef } from 'react';
import { CMS_THEME } from '../../common/enums';
import useIsOverflowX from '../../hooks/useIsOverflowX';

interface SharedStyleProps {
  cmsTheme: CMS_THEME;
}

const buttonWidth = 37.5;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const scrollSnap = css`
  overflow: auto hidden;
  overflow-behavior-x: contain;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media (hover: none) {
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
`;

const ScrollContainer = styled.div<SharedStyleProps>`
  display: flex;
  width: 100%;

  /* Needs a specific enough class list to override passed in style encase of justify: center */
  &.hs__scroll-container.--overflowed {
    justify-content: start;
  }

  > * {
    scroll-snap-align: start;
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
  }
`;

const ScrollButtons = styled.button<SharedStyleProps>`
  background: transparent;
  border: none;
  color: ${({ cmsTheme }) => (cmsTheme === 'dark' ? '#FFFFFF' : '#2b2b2b')};
  position: absolute;

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }
`;

const ContentMask = styled.div`
  width: 100%;
  max-width: calc(100% - (${buttonWidth}px * 2));
  margin: 0 auto;
`;

interface Props {
  children: React.ReactNode;
  activeClass?: string;
  cmsTheme?: CMS_THEME;
  className?: string;
}

const HorizontalScroller: React.FC<Props> = ({
  children,
  activeClass,
  cmsTheme = CMS_THEME.DARK,
  className,
}) => {
  const linkContainerRef = useRef<HTMLDivElement>(null);
  const isOverflow = useIsOverflowX(linkContainerRef);

  useEffect(() => {
    if (!activeClass) return;

    const activeTab = document.querySelector(activeClass);

    if (isOverflow) {
      activeTab?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    }
  }, [activeClass, isOverflow]);

  const onScrollClick = (direction: 'left' | 'right') => {
    if (direction === 'right') {
      linkContainerRef.current?.scrollBy({ left: 100, behavior: 'smooth' });
      return;
    }

    linkContainerRef.current?.scrollBy({ left: -100, behavior: 'smooth' });
  };

  return (
    <Wrapper className={`${className} no-print`}>
      {isOverflow ? (
        <>
          <ScrollButtons
            cmsTheme={cmsTheme}
            className="left hs__button"
            onClick={() => onScrollClick('left')}
            type="button"
            aria-label="scroll left"
          >
            {'<'}
          </ScrollButtons>
          <ScrollButtons
            cmsTheme={cmsTheme}
            className="right hs__button"
            onClick={() => onScrollClick('right')}
            type="button"
            aria-label="scroll right"
          >
            {'>'}
          </ScrollButtons>
        </>
      ) : null}
      <ContentMask className="hs__content-mask">
        <ScrollContainer
          ref={linkContainerRef}
          className={`${scrollSnap} hs__scroll-container ${isOverflow && '--overflowed'}`}
          cmsTheme={cmsTheme}
        >
          {children}
        </ScrollContainer>
      </ContentMask>
    </Wrapper>
  );
};

export default HorizontalScroller;
