import { useLocation } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';

export type SearchPageResult = {
  node: {
    path: { alias: string };
    id: string;
    api_slug: PageType;
    title: string;
    langcode: string;
    default_langcode: boolean;
  };
};

export enum PageType {
  Resources = 'centresquare',
  Events = 'events',
  Micro = 'microbiome-forum',
  Science = 'science-of-nutrition',
  About = 'about',
}

export const PageTypeLabels = {
  [PageType.Resources]: 'CentreSquare',
  [PageType.Events]: 'Events',
  [PageType.Micro]: 'Microbiome Forum',
  [PageType.Science]: 'Science of Nutrition',
  [PageType.About]: 'About Us',
};

export interface FacetCountValue {
  raw_value: 'about' | 'centresqare' | 'event' | 'microbiome-forum' | 'science-of-nutrition';
  value: 'CentreSquare' | 'Events' | 'Microbiome Forum' | 'Science of Nutrition' | 'About Us';
  count: number;
}

/*
 * This a service to request the data from the server. The difference is that,
 * in order to call this as a function, we must wrap it inside a hook.
 * Hooks have the specific rule of needing to be called at the beginning of the component
 * This must not be confused with a normal service, where you just call a function to fetch from a url
 */
function useQuerySearchPages() {
  const data: any = useStaticQuery(graphql`
    query SearchPageQuery {
      allNodeSearchPage(sort: { field_node_weight: ASC }) {
        edges {
          node {
            id
            api_slug: field_api_slug
            title
            path {
              alias
            }
            langcode
            default_langcode
          }
        }
      }
    }
  `);

  return data;
}

export default {
  useGetSearchPages: (language = 'en') => {
    const data = useQuerySearchPages();

    return data.allNodeSearchPage.edges.filter(
      (page: SearchPageResult) => page.node.langcode === language,
    ) as SearchPageResult[];
  },
  useGetActiveSearchPage: (language = 'en') => {
    const data = useQuerySearchPages();
    const location = useLocation();
    let searchPage = data.allNodeSearchPage.edges[0].node;
    const match = data.allNodeSearchPage.edges.filter((page: SearchPageResult) => {
      const langcode = page.node?.langcode || 'en';
      return langcode === language && location.pathname.indexOf(page.node.path.alias) > -1;
    });

    if (match.length) searchPage = match[0].node;
    return searchPage;
  },
  getSearchPageTypeFromFacet: (facet: FacetCountValue['value'] | FacetCountValue['raw_value']) => {
    switch (facet) {
      case 'CentreSquare':
        return PageType.Resources;
      case 'Events':
        return PageType.Events;
      case 'Microbiome Forum':
        return PageType.Micro;
      case 'Science of Nutrition':
        return PageType.Science;
      case 'about':
        return PageType.About;
      default:
        return undefined;
    }
  },
};
