import { styled } from '@linaria/react';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { CMS_THEME } from '../../common/enums';
import { theme } from '../../theme/theme';

const ResponsiveVideo = styled.div`
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;

  iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

const TranscriptBar = styled.div<{ cmsTheme: CMS_THEME }>`
  text-align: center;

  a {
    text-transform: capitalize;
    text-decoration: none;
    color: ${({ cmsTheme }) => theme[cmsTheme].action};
    &:hover {
      color: ${({ cmsTheme }) => theme[cmsTheme].action};
    }
  }
`;

export interface Props {
  embedUrl: string;
  transcript: string;
  cmsTheme?: CMS_THEME;
}

const getTimeIndex = (embedUrl: string): number => {
  const matches = embedUrl.match(/[&?]t=((?<hours>d+)h)?((?<minutes>d+)m)?(?<seconds>d+)s?/);
  const hours = parseInt(matches?.[1] || '0', 10) * 3600;
  const minutes = parseInt(matches?.[1] || '0', 10) * 60;
  const seconds = parseInt(matches?.[1] || '0', 10);
  const timeIndex = hours + minutes + seconds;
  return timeIndex || 0;
};

const VideoEmbed = function ({
  embedUrl,
  transcript,
  cmsTheme = CMS_THEME.LIGHT,
}: Props): React.ReactElement | null {
  const [activeTranscript, setActiveTranscript] = useState(false);
  const matches = embedUrl.match(
    /^https:\/\/(live\.vhall\.com\/v3\/lives\/watch\/)(?<id>[0-9A-Za-z_?]*)$/
  );
  const embedID = matches?.[2];
  const toggleTranscript = () => {
    setActiveTranscript(!activeTranscript);
  };

  if (!embedID) {
    console.warn('Invalid remote video url provided.');
    return null;
  }

  const srcUrl = new URL(`https://live.vhall.com/v3/lives/embedclientvideo/watch/${embedID}`);
  const timeIndex = getTimeIndex(embedUrl);
  srcUrl.searchParams.append('timeIndex', timeIndex.toString());
  srcUrl.searchParams.append('autoPlay', 'false');
  srcUrl.searchParams.append('rel', '0');

  return (
    <>
      <ResponsiveVideo className="order-1">
        <iframe
          width="920"
          height="510"
          src={srcUrl.toString()}
          frameBorder="0"
          allowFullScreen
          title={embedID}
          style={{ borderRadius: '1rem' }}
        />
      </ResponsiveVideo>
      {transcript && (
        <TranscriptBar cmsTheme={cmsTheme} className="order-1">
          <a href="#video-transcript" onClick={toggleTranscript}>
            View video transcript
          </a>
        </TranscriptBar>
      )}
      {transcript && (
        <Modal
          show={activeTranscript}
          size="lg"
          fullscreen="sm-down"
          scrollable
          onHide={toggleTranscript}
        >
          <Modal.Header closeButton>
            <Modal.Title>Video Transcript</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* eslint-disable-next-line react/no-danger */}
            <p className="typography_body" dangerouslySetInnerHTML={{ __html: transcript }} />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default VideoEmbed;
