import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import { styled } from '@linaria/react';
import React from 'react';
import { Accordion } from 'react-bootstrap';
import { CMS_THEME } from '../../common/enums';
import { ComponentColor } from '../../theme/componentColors';
import RichText from '../rich_text/RichText';
import Typography from '../typography/Typography';

type Props = {
  node: IStorageFaq;
  componentColor: ComponentColor;
  headingStyle?: 'h3' | 'h4';
};

const red = 'red';
// This wrapper has the correct facet icon
const StyledAccordionHeaderWrapper = styled.div<{
  componentColor: ComponentColor;
}>`
  /* --bs-accordion-btn-icon: url(${`"data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='${red}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"`}); */
  --bs-accordion-btn-icon: ${({ componentColor }) =>
    `url(${`"data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='${componentColor.color.replace(
      '#',
      '%23'
    )}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"`})`};
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%230c63e4' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  --bs-accordion-border-color: gray;
  --bs-accordion-btn-padding-y: 2.5rem; /* 40px */
  --bs-accordion-btn-padding-x: 2rem; /* 32px */
  --bs-accordion-btn-color: ${({ componentColor }) => componentColor.color};
  --bs-accordion-btn-bg: ${({ componentColor }) => componentColor.background};
`;

const StyledAccordionItem = styled(Accordion.Item)<{ componentColor: CMS_THEME }>`
  color: ${({ componentColor }) => componentColor.color};
  background: ${({ componentColor }) => componentColor.background};
`;

const FaqItem = ({ node, headingStyle = 'h4', componentColor }: Props) => {
  return (
    <AnalyticsPoint
      type="component"
      node={node}
      as={StyledAccordionItem}
      data-id={node.drupal_id}
      componentColor={componentColor}
      className="js-track no-print"
      id={node.id}
      eventKey={node.id}
    >
      <StyledAccordionHeaderWrapper componentColor={componentColor}>
        <Accordion.Header>
          <Typography variant={headingStyle}>{node.question}</Typography>
        </Accordion.Header>
      </StyledAccordionHeaderWrapper>
      <Accordion.Body>
        <RichText body={node.answer.processed} />
      </Accordion.Body>
    </AnalyticsPoint>
  );
};

export default FaqItem;
