import React, { HTMLAttributes, Suspense } from 'react';
import ParagraphFooterRow from '../components/paragraphs/ParagraphFooterRow';
import ParagraphLinkedLogo from '../components/paragraphs/ParagraphLinkedLogo';
import ParagraphMenu from '../components/paragraphs/ParagraphMenu';
import ParagraphNavigation from '../components/paragraphs/ParagraphNavigation';
import ParagraphPlaceholder from '../components/paragraphs/ParagraphPlaceholder';
import ParagraphSearchForm from '../components/paragraphs/ParagraphSearchForm';
import ParagraphSocialLinks from '../components/paragraphs/ParagraphSocialLinks';
import ParagraphText from '../components/paragraphs/ParagraphText';

interface ComponentsProps {
  [index: string]: React.FC<any>;
}

const components: ComponentsProps = {
  paragraph__navigation: ParagraphNavigation,
  paragraph__search_form: ParagraphSearchForm,
  paragraph__menu: ParagraphMenu,
  paragraph__linked_logo: ParagraphLinkedLogo,
  paragraph__social_links: ParagraphSocialLinks,
  paragraph__footer_row: ParagraphFooterRow,
  paragraph__placeholder: ParagraphPlaceholder,
  paragraph__text: ParagraphText,
};

interface Props {
  [key: string]: any;
}
interface GetParagraphProps {
  node: Paragraph;
  props?: Props;
  translations?: Translations;
}

export const getParagraph = ({
  node,
  props,
  translations,
}: GetParagraphProps): React.ReactElement | null => {
  if (components.hasOwnProperty(node.type)) {
    const ParagraphComponent = components[node.type];
    return (
      <Suspense fallback={`Loading ${node.type}...`}>
        <ParagraphComponent node={node} props={props} translations={translations} />
      </Suspense>
    );
  }
  return null;
};

type HtmlAttributes = Record<string, string | number | null | undefined>;

const attributeCheckList: Record<string, string> = {
  ariaLabel: 'aria-label',
};
/**
 * Provided a list of HTML attributes, this func will check each attribute and replace its camel casing with a snake case equivelent found in the local attributeCheckList variable.
 * @param attributes List of attributes to be checked.
 * @returns Null if received invalid attributes argument, otherwise returns the a checked copy of the attributes argument.
 */
export function getHtmlAttributes(
  attributes: HtmlAttributes | undefined,
): HtmlAttributes | undefined {
  if (!attributes) {
    return undefined;
  }

  const attributesCopy = { ...attributes };

  Object.keys(attributeCheckList).forEach(key => {
    if (attributes.hasOwnProperty(key)) {
      attributesCopy[attributeCheckList[key]] = attributesCopy[key];
      delete attributesCopy[key];
    }
  });

  return attributesCopy;
}
