import { css, cx } from '@linaria/core';
import { styled } from '@linaria/react';
import React, { useState } from 'react';
import Button, { ButtonProps } from 'react-bootstrap/Button';

interface Props extends ButtonProps {
  icon?: React.ReactElement;
  label: string;
  onClick: React.MouseEventHandler;
  onDelete: React.KeyboardEventHandler;
  'aria-label': string;
  iconJustifyStart?: boolean;
}

const Fadeout = css`
  animation: fadeout 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) forwards;

  @keyframes fadeout {
    75% {
      opacity: 0;
    }
    100% {
      transform: translateY(-25%);
      opacity: 0;
    }
  }
`;

const StyledChip = styled(Button)`
  align-items: center;
  background-color: #edf0f8;
  border-color: #edf0f8;
  color: #2b2b2b;
  display: inline-flex;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1;
  padding: 0.45rem 0.75rem;
  text-align: center;
  transition: none;
  vertical-align: baseline;
  white-space: nowrap;

  & > * + * {
    margin-left: 0.5rem;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: #edf0f8;
    border-color: #2b2b2b;
    color: #2b2b2b;
  }
`;

function isDeleteKeyboardEvent(event: React.KeyboardEvent) {
  return event.key === 'Backspace' || event.key === 'Delete';
}

const Chip: React.FC<Props> = React.forwardRef<HTMLButtonElement, Props>(
  ({ children, className, onClick, onDelete, iconJustifyStart = false, icon, ...props }, ref) => {
    const [show, setShow] = useState(true);

    const handleClick = (event: React.MouseEvent) => {
      // add transition out animation class...
      setShow(false);
      onClick(event);
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
      if (isDeleteKeyboardEvent(event)) {
        // Prevent browser navigation from occuring.
        event.preventDefault();
      }
    };

    const handleKeyUp = (event: React.KeyboardEvent) => {
      if (onDelete && isDeleteKeyboardEvent(event)) {
        setShow(false);
        onDelete(event);
      }
    };

    return (
      <StyledChip
        ref={ref}
        className={cx('rounded-pill', !show ? Fadeout : '', className)}
        onClick={handleClick}
        onKeyUp={handleKeyUp}
        onKeyDown={handleKeyDown}
        {...props}
      >
        {iconJustifyStart && icon}
        <span aria-hidden>{props.label}</span>
        {!iconJustifyStart && icon}
      </StyledChip>
    );
  }
);

export default Chip;
