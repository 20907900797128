export enum CMS_THEME {
  DARK = 'dark',
  LIGHT = 'light',
  NEUTRAL = 'neutral',
  CREAM = 'cream',
}

export enum CMS_OVERLAY {
  DARK = 'dark',
  LIGHT = 'light',
}
