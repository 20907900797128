import { GatsbyImage, GatsbyImageProps, IGatsbyImageData } from 'gatsby-plugin-image';
import { cx } from '@linaria/core';
import React from 'react';

interface Props extends Media, Omit<GatsbyImageProps, 'alt' | 'image' | 'id'> {
  relationships: {
    field_media_image: {
      gatsbyImage: IGatsbyImageData;
    };
  };
  field_media_image: {
    alt: string;
  };
  internal: {
    type: string;
  };
}

const MediaImage = function ({
  relationships,
  field_media_image,
  internal,
  className,
  loading,
  ...props
}: Props) {
  const image = relationships?.field_media_image?.gatsbyImage;
  const imageAlt = field_media_image?.alt || '';
  if (!image) {
    return null;
  }

  return (
    <GatsbyImage
      data-media-type={internal.type}
      alt={imageAlt}
      image={image}
      role={!imageAlt ? 'presentation' : undefined}
      className={cx(className, 'rounded-3')}
      {...props}
    />
  );
};

export default MediaImage;
