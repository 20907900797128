import React from 'react';
import ScrollTracker from './src/ScrollTracker';
import { searchExceptions } from './src/common/constants';
import {
  generateGaEvent,
  pollObjectOnWindow,
  replaceRouteExceptions,
} from './src/common/functions';
import { IPluginOptions, IStopPoint } from './src/types';

// Leverage route update function to push the page view event.
export const onRouteUpdate = (
  {
    location,
    prevLocation,
  }: {
    location: { pathname: string; search: string; href: string };
    prevLocation: { pathname: string };
  },
  pluginOptions: IPluginOptions
) => {
  const data = pluginOptions.dataLayerName ? window[pluginOptions.dataLayerName] : window.dataLayer;
  const eventName = pluginOptions.routeChangeEventName
    ? pluginOptions.routeChangeEventName
    : 'gatsby-route-change';

  // eslint-disable-next-line
  const checkOneTrustMount = (count: number, callback: any) => {
    if (window.google_tag_manager?.[pluginOptions.gtmID].dataLayer.get('OnetrustActiveGroups')) {
      return callback();
    }
    if (count === 100) {
      return false;
    }

    setTimeout(() => {
      checkOneTrustMount(count + 1, callback);
    }, 50);
  };

  checkOneTrustMount(0, () => {
    data.push({
      event: eventName,
      event_params: {
        page_path: replaceRouteExceptions(searchExceptions, location.pathname, 'replacedPII'),
      },
    });
    data.push({ event: 'optimize.activate' });
  });

  // Wrap inside a timeout to ensure the title has properly been changed.

  // Reset scroll tracker state.
  if (prevLocation && window.resetScrollDepth) {
    window.resetScrollDepth();
  }
};

window.addEventListener(
  'click',
  function (event) {
    if (event.target?.classList?.contains('js-track') || event.target?.closest('.js-track')) {
      const eventTarget = event.target as HTMLAnchorElement;
      const stopPoints: IStopPoint[] = [];
      const keyTarget = eventTarget?.classList?.contains('js-track')
        ? eventTarget
        : eventTarget.closest('.js-track');
      let el: any = eventTarget.closest('.js-track-stop-point');

      while (el) {
        if (el?.classList?.contains('js-track-stop-point')) {
          const type = el.getAttribute('data-type') || '[not set]'; // page, module, component
          const pageType = el.getAttribute('data-page-type') || '[not set]';
          const typeLabel = el.getAttribute('data-type-label') || '[not set]';
          const label = el.getAttribute('data-label') || '[not set]'; // Component Testing for Analytics, 'Page title component'
          const title = el.getAttribute('data-title') || '[not set]';
          const action = el.getAttribute('data-action') || undefined;
          const eventLabel = el.getAttribute('data-event-label') || undefined;
          const category = el.getAttribute('data-category') || undefined;
          const additionalParams = el.getAttribute('data-additional-parmas') || undefined;
          const eventOverride = el.getAttribute('data-event-override') || undefined;
          let addParentLevel = Number(el.getAttribute('data-parent-level' || 0));
          let trueParent = el.parentNode;
          let siblingElement = el;

          if (addParentLevel > 0) {
            // eslint-disable-next-line
            while (addParentLevel > 0) {
              siblingElement = trueParent;
              trueParent = trueParent.parentNode;
              addParentLevel--;
            }
          }

          const siblingArray = Array.from(trueParent?.children || []);
          const index = siblingArray.indexOf(siblingElement);
          const totalSiblings = siblingArray.length;

          stopPoints.push({
            type: type as 'page' | 'module' | 'component',
            label,
            typeLabel,
            title,
            action,
            category,
            pageType,
            index,
            eventLabel,
            eventOverride,
            additionalParams,
            totalSiblings,
          });
        }
        el = el.parentNode;
      }

      // console.group('GA event test logging');
      // console.log({ event });
      // console.log({ eventTarget });
      // console.log({ stopPoints });
      // console.log({ keyTarget });
      // console.log({ el });
      // console.groupEnd();
      generateGaEvent(stopPoints, keyTarget);
    }
  },

  true
);

export const wrapPageElement = ({ element }: any) => {
  return <ScrollTracker>{element}</ScrollTracker>;
};
