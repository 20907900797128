import { styled } from '@linaria/react';
import React from 'react';
import { CMS_THEME } from '../../common/enums';
import { defaultTheme } from '../../theme/defaultTheme';
import { theme } from '../../theme/theme';

import Link from '../Link';

const baseCss = {
  all: 'unset',
  cursor: 'pointer',
  fontFamily: 'Antenna',
  padding: '0.88rem 2rem',
  textShadow: 'none',
  textAlign: 'center',
  transition: 'all 255ms cubic-bezier(0.29, 0.19, 0.405, 1)',
  display: 'inline-flex',
  boxSizing: 'border-box',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
};

enum Target {
  borderRadius = 'borderRadius',
  border = 'border',
  background = 'background',
  color = 'color',
  textDecoration = 'textDecoration',
}

interface Props {
  cmsTheme: CMS_THEME;
  hover?: boolean;
  variant?: 'btn' | 'btn-outline' | 'btn-link';
  shape?: 'pill' | 'square';
}

interface HelperProps extends Props {
  target: Target;
}

const buttonHelper = ({
  cmsTheme,
  variant = 'btn',
  target,
  hover,
  shape = 'pill',
}: HelperProps) => {
  const currentTheme = theme[cmsTheme] || theme.light;

  const x = {
    [Target.background]: () => {
      if (variant === 'btn-outline') {
        if (hover) {
          return (
            currentTheme?.button?.secondary?.withAlpha(0.3) || currentTheme.text.withAlpha(0.3)
          );
        }

        return 'transparent';
      }

      if (variant === 'btn-link') {
        return 'transparent';
      }

      if (shape === 'square') {
        if (hover) return cmsTheme === 'dark' ? currentTheme.gray[500] : currentTheme.gray[300];
        return currentTheme.background.paper;
      }

      if (hover) {
        return currentTheme?.button?.primary?.withAlpha(0.3) || currentTheme.text.withAlpha(0.3);
      }

      return currentTheme?.button?.primary?.background || currentTheme.text.default;
    },
    [Target.color]: () => {
      if (variant === 'btn-link') {
        return currentTheme.action;
      }

      if (variant === 'btn-outline') {
        return currentTheme?.button?.secondary?.background || currentTheme.text.default;
      }

      if (shape === 'square') {
        return currentTheme.text.default;
      }

      if (hover) return currentTheme.text.hover || currentTheme.text.default;

      return currentTheme?.button?.primary.text;
    },
    [Target.border]: () => {
      if (hover) {
        return currentTheme?.button?.primary?.border || '1px solid transparent';
      }

      if (shape === 'square' || variant === 'btn-link') {
        return 'none';
      }
      if (variant === 'btn-outline') {
        return `1px solid ${
          currentTheme?.button?.secondary?.background || currentTheme.text.default
        }`;
      }

      return '1px solid transparent';
    },
    [Target.borderRadius]: () => {
      return currentTheme?.shape?.rounded1 || 'inherit';
    },
    [Target.textDecoration]: () => {
      if (variant === 'btn-link') {
        return 'underline';
      }

      return 'none';
    },
  };

  return x[target]();
};

export const Button = styled.button<Props>`
  ${baseCss}
  border-radius: ${({ cmsTheme, variant }) =>
    buttonHelper({ cmsTheme, variant, target: Target.borderRadius })};
  border: ${({ cmsTheme, variant }) => buttonHelper({ cmsTheme, variant, target: Target.border })};
  background: ${({ cmsTheme, shape, variant }) =>
    buttonHelper({ cmsTheme, shape, variant, target: Target.background })};
  color: ${({ cmsTheme, shape, variant }) =>
    buttonHelper({ cmsTheme, shape, variant, target: Target.color })};
  text-decoration: ${({ cmsTheme, shape, variant }) =>
    buttonHelper({ cmsTheme, shape, variant, target: Target.textDecoration })};

  &:hover {
    background: ${({ cmsTheme, shape, variant }) =>
      buttonHelper({ cmsTheme, shape, variant, hover: true, target: Target.background })};
    color: ${({ cmsTheme, shape, variant }) =>
      buttonHelper({ cmsTheme, shape, variant, hover: true, target: Target.color })};
    border: ${({ cmsTheme, shape, variant }) =>
      buttonHelper({ cmsTheme, shape, variant, hover: true, target: Target.border })};
  }

  &:focus-visible {
    outline: 5px auto -webkit-focus-ring-color;
  }

  @media (min-width: ${theme.media.sm}) {
    width: auto;
  }
`;

export const ButtonLink = styled(Link)<Props>`
  ${baseCss}
  border-radius: ${({ cmsTheme, variant }) =>
    buttonHelper({ cmsTheme, variant, target: Target.borderRadius })};
  border: ${({ cmsTheme, variant }) => buttonHelper({ cmsTheme, variant, target: Target.border })};
  background: ${({ cmsTheme, shape, variant }) =>
    buttonHelper({ cmsTheme, shape, variant, target: Target.background })};
  color: ${({ cmsTheme, shape, variant }) =>
    buttonHelper({ cmsTheme, shape, variant, target: Target.color })} !important;
  text-decoration: ${({ cmsTheme, shape, variant }) =>
    buttonHelper({ cmsTheme, shape, variant, target: Target.textDecoration })};

  &:hover {
    background: ${({ cmsTheme, shape, variant }) =>
      buttonHelper({ cmsTheme, shape, variant, hover: true, target: Target.background })};
    color: ${({ cmsTheme, shape, variant }) =>
      buttonHelper({ cmsTheme, shape, variant, hover: true, target: Target.color })} !important;
    border: ${({ cmsTheme, shape, variant }) =>
      buttonHelper({ cmsTheme, shape, variant, hover: true, target: Target.border })};
  }

  &:focus-visible {
    outline: 5px auto -webkit-focus-ring-color;
  }

  @media (min-width: ${theme.media.sm}) {
    width: auto;
  }
`;

interface ButtonDivProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  cmsTheme: CMS_THEME;
  hover?: boolean;
  variant?: 'btn' | 'btn-outline' | 'btn-link';
  shape?: 'pill' | 'square';
  [prop: string]: any;
}
export const ButtonDiv = styled.div`
  ${baseCss}
  border-radius: ${({ cmsTheme, variant }) =>
    buttonHelper({ cmsTheme, variant, target: Target.borderRadius })};
  border: ${({ cmsTheme, variant }) => buttonHelper({ cmsTheme, variant, target: Target.border })};
  background: ${({ cmsTheme, shape, variant }) =>
    buttonHelper({ cmsTheme, shape, variant, target: Target.background })};
  color: ${({ cmsTheme, shape, variant }) =>
    buttonHelper({ cmsTheme, shape, variant, target: Target.color })};
  text-decoration: ${({ cmsTheme, shape, variant }) =>
    buttonHelper({ cmsTheme, shape, variant, target: Target.textDecoration })};

  &:hover {
    background: ${({ cmsTheme, shape, variant }) =>
      buttonHelper({ cmsTheme, shape, variant, hover: true, target: Target.background })};
    color: ${({ cmsTheme, shape, variant }) =>
      buttonHelper({ cmsTheme, shape, variant, hover: true, target: Target.color })};
  }

  &:focus-visible {
    outline: 5px auto -webkit-focus-ring-color;
  }

  @media (min-width: ${theme.media.sm}) {
    width: auto;
  }
`;
