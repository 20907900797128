import { Script, graphql, useStaticQuery } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as React from 'react';

import '../theme/overrides/pfa_override.css';

const LyticsSignUp: React.FC = function ({ children }) {
  const { i18n } = useTranslation();
  const activeLanguage = i18n.language || 'en';

  const lyticsFormData = useStaticQuery(graphql`
    {
      allStorageLyticsForm {
        edges {
          node {
            name
            langcode
            field_professional_role {
              key
              label
            }
            field_professional_sector {
              key
              label
            }
            field_country {
              key
              label
            }
            field_language_preference {
              key
              label
            }
            msg: field_message
            close: field_close
            agree: field_agree {
              processed
            }
            headline: field_headline
            accept: field_accept
            btn: field_sign_up
            firstName: field_first_name
            lastName: field_last_name
            email: field_email
            emailConfirmation: field_email_confirmation
            emailMismatch: field_email_confirm_error_msg
            errorHeadline: field_error_headline
            errorMsg: field_error_msg
            specialistPlaceholder: field_specialist
            successHeadline: field_success_headline
            successMsg: field_success_msg
            countryLabel: field_country_label
            languagePreferenceLabel: field_language_preference_label
            professionalRoleLabel: field_professional_role_label
            professionalSectorLabel: field_professional_sector_label
            otherSector: field_other_sector
            requiredLabel: field_required_label
          }
        }
      }
    }
  `);

  const activeLanguageContent = lyticsFormData.allStorageLyticsForm.edges.find(
    ({ node }) => node.langcode === activeLanguage,
  );

  if (activeLanguageContent?.node?.field_country && !activeLanguageContent?.node?.country) {
    activeLanguageContent.node.country = activeLanguageContent.node.field_country.map(
      (country: any) => ({
        label: country.label,
        value: country.key,
      }),
    );
  }

  if (
    activeLanguageContent?.node?.field_professional_role &&
    !activeLanguageContent?.node?.professionalRole
  ) {
    activeLanguageContent.node.professionalRole =
      activeLanguageContent.node.field_professional_role.map((role: any) => ({
        label: role.label,
        value: role.key,
      }));
  }

  if (
    activeLanguageContent?.node?.field_professional_sector &&
    !activeLanguageContent?.node?.professionalSector
  ) {
    activeLanguageContent.node.professionalSector =
      activeLanguageContent.node.field_professional_sector.map((sector: any) => ({
        label: sector.label,
        value: sector.key,
      }));
  }

  if (
    activeLanguageContent?.node?.field_language_preference &&
    !activeLanguageContent?.node?.languagePreference
  ) {
    activeLanguageContent.node.languagePreference =
      activeLanguageContent.node.field_language_preference.map((preference: any) => ({
        label: activeLanguage === 'en' ? preference.key : preference.label, // fix odd issue when english labels are not correct
        value: preference.key,
      }));
  }

  return (
    <div class="w-100" onClick={() => triggerPiModal(activeLanguageContent.node)}>
      <Script src="/lytics.js" />
      <Script id="analytics" src="https://c.lytics.io/static/pathfora.min.js" />

      {children}

      <div id="js-lytics-modal" />
    </div>
  );
};

export default LyticsSignUp;
