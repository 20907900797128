import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import React, { useContext } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import PageCard from '../page_card/PageCard';
import PageCardSkeleton from '../page_card/PageCardSkeleton';
import NoResults from './NoResults';
import { SearchListingContext } from './SearchListingContext';

const ContentSearchResults: React.FC = function () {
  const context = useContext(SearchListingContext);

  if (!context) {
    throw new Error(
      'Child components of SearchListing cannot be rendered outside the SearchListing component!',
    );
  }

  const { data, loaded } = context || {};
  const { search_results } = data || {};

  if (!loaded) {
    return (
      <Row className="mb-5 gy-3 gy-md-4 gx-2 gx-md-3">
        {[...Array(12)].map((_, i) => {
          return (
            <Col key={`loading-card-${i}`} xs={6} md={4} className="d-flex">
              <PageCardSkeleton />
            </Col>
          );
        })}
      </Row>
    );
  }

  if (!search_results) {
    return <NoResults />;
  }

  return (
    <Row id="search-results" className="mb-5 gap-3 gap-lg-5 gy-3 gy-md-4 gx-2 gx-md-3">
      {Object.values(search_results).map(node => {
        return (
          <Row key={node.nid}>
            <AnalyticsPoint
              type="component"
              category="search_result_selected"
              action={node.title}
              eventLabel="pages"
              node={{ ...node }}
            >
              <PageCard link={node.url} title={node.title} summary={node.summary} />
            </AnalyticsPoint>
          </Row>
        );
      })}
    </Row>
  );
};

ContentSearchResults.displayName = 'SearchListing.ContentSearchResults';

export default ContentSearchResults;
