import { ICustomPages } from './types';

function removeTrailingSlash(str: string) {
  if (str && str.endsWith('/')) {
    return str.slice(0, -1);
  }
  return str;
}

const BASE_URL = removeTrailingSlash(process.env.GATSBY_PFF_BASE_URL || '');

const CustomPages: ICustomPages = {
  '/do-not-sell-or-share-my-personal-information': {
    component: 'src/templates/custom-pages/do-not-sell.tsx',
  },
  '/dogs/dog-breeds/collections': {
    component: 'src/templates/custom-pages/breed-collections-listing.tsx',
    context: {
      species: 1117, // Species ID for Dogs.
    },
  },
  '/cats/cat-breeds/collections': {
    component: 'src/templates/custom-pages/breed-collections-listing.tsx',
    context: { species: 1120 }, // Species ID for Cats.
  },
  '/pet-food-finder-direct': {
    component: 'src/templates/custom-pages/pet-food-finder.tsx',
    context: {
      client_id: 'f5924d3a-3208-45c1-a804-c1ed1ac5a99d',
      baseUrl: BASE_URL,
      isProduction: true,
      theme_key: 'pff',
      consumer_key: 'default',
      pricespider_widget_id: '60f0869109ece10018627c2e',
    },
  },
  '/pro-plan/myplan-direct': {
    component: 'src/templates/custom-pages/pet-food-finder.tsx',
    context: {
      client_id: 'f5924d3a-3208-45c1-a804-c1ed1ac5a99d',
      baseUrl: BASE_URL,
      isProduction: true,
      theme_key: 'my_plan',
      consumer_key: 'default',
      pricespider_widget_id: '60f0869109ece10018627c2e',
    },
  },
  '/pro-plan/myplan-psp-direct': {
    component: 'src/templates/custom-pages/pet-food-finder.tsx',
    context: {
      client_id: 'f5924d3a-3208-45c1-a804-c1ed1ac5a99d',
      baseUrl: BASE_URL,
      isProduction: true,
      theme_key: 'pet_supplies_plus',
      consumer_key: 'default',
      pricespider_widget_id: '63fe5067d5cdda0066d753ba',
    },
  },
  '/pro-plan/myplan-vet-direct': {
    component: 'src/templates/custom-pages/pet-food-finder.tsx',
    context: {
      client_id: 'f5924d3a-3208-45c1-a804-c1ed1ac5a99d',
      baseUrl: BASE_URL,
      isProduction: true,
      theme_key: 'vet_direct',
      consumer_key: 'default',
      pricespider_widget_id: '60f0869109ece10018627c2e',
    },
  },
  '/purina-one/pet-food-finder-direct': {
    component: 'src/templates/custom-pages/pet-food-finder.tsx',
    context: {
      client_id: 'f5924d3a-3208-45c1-a804-c1ed1ac5a99d',
      baseUrl: BASE_URL,
      isProduction: true,
      theme_key: 'purina_one',
      consumer_key: 'default',
      pricespider_widget_id: '60f0869109ece10018627c2e',
    },
  },
  '/international-sites': {
    component: 'src/templates/custom-pages/international-sites.tsx',
  },
};

export default CustomPages;
