export interface Calories {
  lose: number | null;
  maintain: number | null;
  idealWeight: number | null;
  unit: string | null;
}

export const BASE_ROUND_FACTOR = 10;
const LB_TO_KG = 0.453592;
const KG_TO_LB = 2.2046226218;
/**
 * Number to substract from BCS score to determine BCS Adjustment.
 */
const BCS_ADJUSTMENT_FACTOR = 5;
/**
 * Multiplier to determine BCS adjusted weight.
 */
const BCS_ADJUSTMENT_MULTIPLIER = 0.1;
/**
 * Multiplier to determine calories for Adult dogs.
 */
const DOG_ADULT_MULTIPLIER = 139;

/**
 * Multiplier to determine calories for Senior dogs.
 */
const DOG_SENIOR_MULTIPLIER = 121;

/**
 * Multiplier to determine calories for cats.
 */
const CAT_ADULT_MULTIPLIER = 56;
const CAT_MATURE_MULTIPLIER = 50;
const CAT_SENIOR_MULTIPLIER = 60;
const CAT_GERIATRIC_MULTIPLIER = 80;
/**
 * Factor to determine Metabolic Weight in Dogs.
 */
const DOG_MW_FACTOR = 0.67;

/**
 * Multiplier to determine calories when goal is to lose weight.
 */
const DOG_LOSE_WEIGHT_MULTIPLIER = 104;
const CAT_LOSE_WEIGHT_MULTIPLIER = 40;

/**
 * The BCS limit to determine if the "Lose weight" goal option visibility.
 *
 * Anything lower than this number will hide the option.
 */
export const BCS_LOSE_WEIGHT_THRESHOLD = 4;
export const BCS_LOSE_WEIGHT_THRESHOLD_CAT = 5;

/**
 * The BCS limit to determine if the "Maintain weight" goal option visibility.
 *
 * Anything greater than this number will hide the option.
 */
export const BCS_MAINTAIN_WEIGHT_THRESHOLD = 8;
export const BCS_MAINTAIN_WEIGHT_THRESHOLD_CAT = 7;

export const SERVING_CALORIES = 453;

export const calculateCalories = (
  species: 'cat' | 'dog',
  ageGroup: string,
  weight: number,
  units: 'lb' | 'kg',
  bcs: number
) => {
  const calories: Calories = {
    maintain: null,
    lose: null,
    idealWeight: null,
    unit: '',
  };

  const bcsAdjustment = bcs - BCS_ADJUSTMENT_FACTOR;
  const kgWeight = units === 'kg' ? weight : weight * LB_TO_KG;
  const bcsAdjustedWeight = kgWeight - bcsAdjustment * BCS_ADJUSTMENT_MULTIPLIER * kgWeight;

  calories.idealWeight = units === 'kg' ? bcsAdjustedWeight : bcsAdjustedWeight * KG_TO_LB;
  calories.unit = `${units}s`;

  let multiplier = 1;
  let metabolicWeight;

  switch (species) {
    case 'dog':
      multiplier = ageGroup === 'adult' ? DOG_ADULT_MULTIPLIER : DOG_SENIOR_MULTIPLIER;
      metabolicWeight = bcsAdjustedWeight ** DOG_MW_FACTOR;
      calories.maintain = metabolicWeight * multiplier;
      if (bcs > BCS_LOSE_WEIGHT_THRESHOLD) {
        calories.lose = metabolicWeight * DOG_LOSE_WEIGHT_MULTIPLIER;
      }
      break;
    case 'cat':
      switch (ageGroup) {
        case 'adult':
          multiplier = CAT_ADULT_MULTIPLIER;
          break;
        case 'mature':
          multiplier = CAT_MATURE_MULTIPLIER;
          break;
        case 'senior':
          multiplier = CAT_SENIOR_MULTIPLIER;
          break;
        case 'geriatric':
          multiplier = CAT_GERIATRIC_MULTIPLIER;
          break;
        default:
          multiplier = CAT_ADULT_MULTIPLIER;
          break;
      }
      calories.maintain = bcsAdjustedWeight * multiplier;
      if (bcs > BCS_LOSE_WEIGHT_THRESHOLD_CAT) {
        calories.lose = bcsAdjustedWeight * CAT_LOSE_WEIGHT_MULTIPLIER;
      }
      break;
    default:
      break;
  }
  return calories;
};
