import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { sendIt } from 'gatsby-plugin-purina-analytics/common/functions';
import { processDrupalSearchResponse } from '../utils/helpers';

const apiEndpoint = process.env.GATSBY_DRUPAL_ENDPOINT || '';
const apiKey = process.env.GATSBY_DRUPAL_API_KEY || '';

type Languages = 'en' | 'es' | 'fr' | 'de' | 'it' | 'ja' | 'pt-br' | 'zh-hans' | (string & {});

const getDrupalSearch = async (params: SearchParams, language: Languages = 'en') => {
  const langPrefix = language === 'en' ? '' : `${language}/`;
  const searchPageApiEndpoint = `${apiEndpoint}${langPrefix}api/search/${
    typeof params.api_slug !== 'undefined' ? params.api_slug : ''
  }`;

  const response = await axios.get(searchPageApiEndpoint, {
    params,
    headers: {
      'api-key': apiKey,
    },
  });

  const formattedParams = {
    ...params,
    api_slug: typeof params.api_slug !== 'undefined' ? params.api_slug : 'centresquare',
  };

  const result = processDrupalSearchResponse({ ...response.data, params: formattedParams });
  result.api_slug = formattedParams.api_slug;
  return result;
};

export const useSearchDrupal = (params: SearchParams, language: Languages) => {
  const fetcher = () => getDrupalSearch(params, language);
  const { data, error, isLoading, isFetching, isRefetching } = useQuery(
    ['searchDrupal', params],
    fetcher,
    {
      staleTime: 3600000,
      refetchOnWindowFocus: false,
      onSuccess: result => {
        const analyticsData = {
          event: 'search',
          eventCategory: 'search',
          eventAction: params.keywords ?? '',
          eventLabel: String(params.category),
          eventParams: {
            internal_search_term: params.keywords,
            search_category: String(params.category),
            search_page_type: 'landing',
            num_search_results: String(result.search_results?.length || 0),
          },
        };
        sendIt(analyticsData);
      },
    },
  );

  return { data, error, isLoading, isRefetching, isFetching };
};

// const getArticles = async (params: AxiosRequestConfig) => {
//   const response = await axios.get<DrupalArticleListingSearch>(`${apiEndpoint}api/articles`, {
//     headers: {
//       'api-key': apiKey,
//     },
//     params,
//   });
//   return response.data;
// };

// export const useSearchArticles = (config: AxiosRequestConfig) =>
//   useQuery({
//     queryKey: ['getArticles', config],
//     queryFn: () => getArticles(config || {}),
//   });
