import { PageProps, graphql } from 'gatsby';
import * as React from 'react';
import { getCmsComponent } from 'src/utils/cmsComponentHelpers';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import { getHelmetPropsFromMetatagProps, getJsonFromMetatagProps } from '../utils/metatagHelpers';

interface DataProps {
  node: NodeContent;
  translations: Translations;
}

const ResourceTemplate: React.FC<PageProps<DataProps>> = function ({
  data: { node, translations },
}) {
  const helmetProps = getHelmetPropsFromMetatagProps(node.metatag);
  const schemaMarkup = getJsonFromMetatagProps(node.metatag);

  const components = node.relationships?.components;
  return (
    <Layout
      node={node}
      language={node.langcode}
      metaData={node.metatag}
      translations={translations}
    >
      <Seo {...helmetProps} schemaMarkup={schemaMarkup} />
      {components?.map((component, index) => {
        return (
          <React.Fragment key={component.id}>
            {getCmsComponent({ node: component, index })}
          </React.Fragment>
        );
      })}
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!, $language: String!, $nid: Int!) {
    node: nodeEvent(id: { eq: $id }, langcode: { eq: $language }) {
      internal {
        type
      }
      id
      path {
        alias
      }
      default_langcode
      langcode
      title
      ...Metatag
      body {
        processed
      }
      field_event_speakers
      field_link {
        title
        uri
      }
      relationships {
        components: field_storage {
          type: __typename
          ...StorageArticleCards
          ...StorageImageContent
          ...StorageHeroCard
          ...StorageManualCards
          ...StorageHighlights
          ...StorageRichText
          ...StoragePageTitle
          ...StorageBanner
          ...StorageBrandGrid
          ...StorageFaqSection
          ...StorageUnfilteredHtml
          ...StorageSubnav
          ...StorageCardGrid
          ...StorageRelatedContent
          ...StorageCategoryCards
          ...StorageContactCards
          ...StorageVideo
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    translations: allNodeEvent(
      filter: { drupal_internal__nid: { eq: $nid }, langcode: { ne: $language } }
    ) {
      edges {
        node {
          langcode
          path {
            alias
          }
        }
      }
    }
  }
`;

export default ResourceTemplate;
