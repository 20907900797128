import { css, cx } from '@linaria/core';
import { styled } from '@linaria/react';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { Stack } from 'react-bootstrap';
import RichText from 'src/components/rich_text/RichText';
import { CMS_THEME } from '../common/enums';
import { ButtonLink } from '../components/button/Button';
import Typography from '../components/typography/Typography';
import { theme } from '../theme/theme';

const StyledGrid = styled.div<{ columns: string; cmsTheme: CMS_THEME }>`
  --grid-layout-gap: 1rem;
  --grid-column-count: ${props => props.columns};
  --grid-item--min-width: 250px;

  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
  --grid-item--max-width: calc((100% - var(--total-gap-width)) / var(--grid-column-count));

  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr)
  );
  color: ${({ cmsTheme }) => theme[cmsTheme].text.default};
  column-gap: var(--grid-layout-gap);
  row-gap: 48px;

  .description {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    color: #424242;
    display: -webkit-box;
    overflow: hidden;
  }

  .description_2x {
    -webkit-line-clamp: 4;
  }

  .duration {
    font-weight: 500;
  }

  .cta,
  .cta a {
    width: 100%;
  }

  @media (min-width: ${theme.media.lg}) {
    .cta,
    .cta a {
      width: auto;
    }
  }
`;

const StyledStack = styled(Stack)<{ cmsTheme: CMS_THEME }>`
  background-color: ${({ cmsTheme }) => theme[cmsTheme].background.default};
  color: ${({ cmsTheme }) => theme[cmsTheme].text.default};
`;

const gridClass = css`
  padding-left: 1rem;
  @media (min-width: 450px) {
    padding-right: 1rem;
  }
`;

const defaultSettings = {
  entity_heading: {
    element: 'h2',
    style: 'typography_h2',
  },
  entity_subheading: {
    element: 'h3',
    style: 'typography_h3',
  },
  entity_buttons: {
    primary_button: 'btn',
  },
  entity_variant: {
    text_align: 'left',
    theme: CMS_THEME.LIGHT,
  },
  entity_rows_columns: {
    columns: '2',
  },
};
type AlternativeUrl = {
  url?: string;
  uri?: string;
};

type ContentCardItemProps = NodeContent & {
  mode?: string;
  buttonVariant: 'btn' | 'btn-outline' | 'btn-link';
  columns: number;
  imgSize: 'w400' | 'w800';
  cmsTheme: CMS_THEME;
  indexOfComponent: number;
  indexOfModule: number;
  drupal_id: string;
  field_override_button_label?: string;
  field_alternative_url?: AlternativeUrl;
};

const ContentCardItem = (el: ContentCardItemProps): JSX.Element => {
  const { t } = useTranslation();

  const headingStyle = el.columns < 4 ? 'h3' : 'h4';
  const { title } = el;
  const description = el.body?.processed || '';

  const type = el.internal?.type?.replace('node__', '');

  const resourceType = el.relationships?.field_type && el.relationships?.field_type.length > 0;
  const hasResourceMedia =
    el.relationships?.field_resource_media && el.relationships?.field_resource_media.length > 0;

  function setPath() {
    if (hasResourceMedia) {
      return el.relationships?.field_resource_media[0]?.relationships?.field_media_document.uri.url;
    }
    if (el.field_alternative_url?.uri) {
      return el.field_alternative_url?.uri;
    }
    return el.path.alias;
  }

  const cta = (
    el.field_override_button_label
      ? el.field_override_button_label
      : (resourceType ? el.relationships?.field_type[0]?.field_button_label : '') ||
        `${t('view')} ${type}`
  ).toUpperCase();
  const duration = Array.isArray(el.relationships?.duration)
    ? el?.relationships?.duration[0]?.name
    : el?.relationships?.event_duration?.name || el?.relationships?.duration?.name || '';

  return (
    <AnalyticsPoint
      as={Stack}
      data-id={el.drupal_id}
      className="flex-column"
      gap={3}
      type="component"
      node={el}
    >
      {title && (
        <Typography variant={headingStyle} className="fw-light">
          {title}
        </Typography>
      )}
      {/* eslint-disable-next-line react/no-danger */}
      {description && (
        <p
          className={`description ${el.columns === 2 && 'description_2x'}`}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
      {el?.path?.alias && (
        <div className="d-flex gx-3 align-items-center mt-auto flex-wrap flex-lg-nowrap">
          <div className="cta me-lg-3">
            <ButtonLink variant={el.buttonVariant} to={setPath()} cmsTheme={el.cmsTheme}>
              {cta}
            </ButtonLink>
          </div>
          {duration && <span className="duration fw-medium mt-1 mt-lg-0">{duration}</span>}
        </div>
      )}
    </AnalyticsPoint>
  );
};

type StorageRelatedContentProps = {
  node: IStorageRelatedContent;
};

const StorageRelatedContent = ({ node: relatedContent }: StorageRelatedContentProps) => {
  const settings = Object.assign(defaultSettings, relatedContent?.behaviors || {});
  const themeType = relatedContent.relationships.theme?.field_color_type ?? 'light';
  const cmsTheme = settings.entity_variant.theme;
  const { primary_button: buttonStyle } = settings.entity_buttons;
  const { text_align: textAlign } = settings.entity_variant;
  const columns = settings.entity_rows_columns?.columns || '2';
  const headingElement = settings.entity_heading?.element || 'h2';
  const headingStyle = settings.entity_heading?.style || 'typography_h2';
  const subheadingElement = settings.entity_subheading?.element || 'h3';
  const subheadingStyle = settings.entity_subheading?.style || 'typography_h3';
  const {
    relationships: { related_content },
  } = relatedContent;

  const renderCardItem = (element: NodeContent, i: number) => (
    <ContentCardItem
      indexOfModule={relatedContent.indexOnPage}
      indexOfComponent={i}
      {...element}
      cmsTheme={cmsTheme}
      columns={Number(columns)}
      buttonVariant={buttonStyle}
      imgSize={Number(columns) > 3 ? 'w400' : 'w800'}
      key={element.id}
      mode={themeType}
    />
  );

  // If related_content are all drafts, render nothing.
  if (!related_content || related_content.length === 0) {
    return null;
  }

  return (
    <StyledStack data-id={relatedContent.drupal_id} cmsTheme={cmsTheme} className="no-print">
      <Stack className={cx('py-5 gap-5 container-xxl', `text-${textAlign}`, gridClass)}>
        <div>
          {relatedContent?.title && (
            <Typography key="card-title" variant={headingElement} className={headingStyle}>
              {relatedContent?.title}
            </Typography>
          )}
          {relatedContent?.subtitle && (
            <Typography key="card-subtitle" variant={subheadingElement} className={subheadingStyle}>
              {relatedContent?.subtitle}
            </Typography>
          )}
          {relatedContent.body?.processed && (
            <RichText
              key="card-content"
              // eslint-disable-next-line react/no-danger
              body={relatedContent.body?.processed}
            />
          )}
        </div>
        <div className="gap-5 d-flex flex-column">
          <StyledGrid
            columns={columns}
            key="card-grid-desktop"
            cmsTheme={cmsTheme}
            className={`text-${textAlign}`}
          >
            {related_content.map(renderCardItem)}
          </StyledGrid>
        </div>
      </Stack>
    </StyledStack>
  );
};

export default StorageRelatedContent;
