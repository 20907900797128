import React, { useEffect, useMemo, useState } from 'react';
import { useUniqueId } from '../../hooks/useUniqueId';
import ContentSearchResults from './ContentSearchResults';
import EventSearchResults from './EventSearchResults';
import FacetModal from './FacetModal';
import Pagination from './Pagination';
import ResourceSearchResults from './ResourceSearchResults';
import SearchFacets from './SearchFacets';
import SearchHeader from './SearchHeader';
import { SearchListingContext } from './SearchListingContext';
import Sorting from './Sorting';

interface SearchListingComposition {
  SearchFacets: typeof SearchFacets;
  ContentSearchResults: typeof ContentSearchResults;
  ResourceSearchResults: typeof ResourceSearchResults;
  EventSearchResults: typeof EventSearchResults;
  Pagination: typeof Pagination;
  SearchHeader: typeof SearchHeader;
  FacetModal: typeof FacetModal;
  Sorting: typeof Sorting;
}

const SearchListing: React.FC<DrupalSearchResponse & { children: React.ReactElement }> &
  SearchListingComposition = function ({ children, defaultLoadedState = false, ...props }) {
  const id = useUniqueId({ prefix: 'SearchListing' });
  const [data, setData] = useState<DrupalSearch | undefined>();
  const [facetModalActive, setFacetModalActive] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [loaded, setLoaded] = useState(defaultLoadedState);

  const { data: d, error: e, loaded: l } = props || {};

  useEffect(() => {
    setData(d);
    setError(e);
    setLoaded(l);
  }, [d, e, l]);

  const searchListingContextValue = useMemo(
    () => ({ id, data, error, loaded, facetModalActive, setFacetModalActive, setLoaded }),
    [data, error, facetModalActive, id, loaded],
  );

  return (
    <SearchListingContext.Provider value={searchListingContextValue}>
      {children}
    </SearchListingContext.Provider>
  );
};

SearchListing.SearchFacets = SearchFacets;
SearchListing.ResourceSearchResults = ResourceSearchResults;
SearchListing.EventSearchResults = EventSearchResults;
SearchListing.ContentSearchResults = ContentSearchResults;
SearchListing.Pagination = Pagination;
SearchListing.SearchHeader = SearchHeader;
SearchListing.FacetModal = FacetModal;
SearchListing.Sorting = Sorting;
export default SearchListing;
