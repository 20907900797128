import { mdiOpenInNew } from '@mdi/js';
import Icon from '@mdi/react';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import { cx } from '@linaria/core';
import { styled } from '@linaria/react';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { CMS_THEME } from '../common/enums';
import Link from '../components/Link';
import { theme } from '../theme/theme';
import { getMedia } from '../utils/mediaHelpers';

type Props = {
  node: IStorageContactCards;
};

const DefaultSettings = {
  entity_heading: {
    element: 'h2',
    style: 'typography_h2',
  },
  entity_variant: {
    theme: CMS_THEME.LIGHT,
  },
};

const Wrapper = styled(Container)<{ cmsTheme: CMS_THEME }>`
  background-color: ${({ cmsTheme }) => theme[cmsTheme].background.default};
  color: ${({ cmsTheme }) => theme[cmsTheme].text.default};
`;

const StyledLogoCard = styled(Link)<{ cmsTheme: CMS_THEME }>`
  color: ${({ cmsTheme }) => theme[cmsTheme].text.default};
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    color: ${({ cmsTheme }) => theme[cmsTheme].text.default};
    cursor: pointer;
    text-decoration: underline;
  }
`;

const StyledRow = styled(Row)`
  &::after {
    flex-grow: 2;
    content: '';
    @media (min-width: ${theme.media.lg}) {
      flex-grow: 0;
    }
  }
`;

const StorageContactCards: React.FC<Props> = ({ node: contactCards }) => {
  const settings = Object.assign(DefaultSettings, contactCards?.behaviors || {});
  const cmsTheme = settings.entity_variant.theme;
  const { element: HeadingElement, style: headingStyle } = settings.entity_heading;

  // If all cards are draft, don't render.
  if (!contactCards?.relationships?.cards || contactCards.relationships.cards.length === 0) {
    return null;
  }

  return (
    <Wrapper
      data-id={contactCards.drupal_id}
      className="py-3 py-lg-5 px-0"
      fluid
      cmsTheme={cmsTheme}
    >
      <Container className={cx(contactCards?.title ? 'pb-3 pb-lg-5' : '', 'text-center')}>
        {contactCards?.title && (
          <HeadingElement className={cx(headingStyle)}>{contactCards.title}</HeadingElement>
        )}
      </Container>
      <Container>
        <StyledRow className="mt-3 mt-lg-5 justify-content-center">
          {contactCards.relationships?.cards.map((card, index) => {
            const fieldMedia = card.relationships?.field_media[0];
            const icon = card.link.options?.attributes?.icon ?? true;
            const attributes = { ...card.link.options?.attributes, icon };
            const openInNew = attributes?.target === '_blank';
            return (
              <AnalyticsPoint
                type="component"
                node={card}
                as={Col}
                data-id={card.drupal_id}
                key={index}
                xs={6}
                lg={3}
                className="mb-3 mb-lg-5"
              >
                <StyledLogoCard
                  to={card.link.url}
                  cmsTheme={cmsTheme}
                  className="d-flex flex-column text-center align-items-center gap-3 px-3"
                  {...attributes}
                >
                  {getMedia({ media: fieldMedia, style: { width: '150px' } })}
                  {card.link?.title && (
                    <div className="d-inline-flex gap-2 align-items-center">
                      {openInNew && <Icon path={mdiOpenInNew} size={0.6} />}
                      <span className="typography_h5">{card.link.title}</span>
                    </div>
                  )}
                </StyledLogoCard>
              </AnalyticsPoint>
            );
          })}
        </StyledRow>
      </Container>
    </Wrapper>
  );
};

export default StorageContactCards;
