import { cx } from '@linaria/core';
import { styled } from '@linaria/react';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import EmailIcon from 'src/assets/icons/email-icon';
import FacebookIcon from 'src/assets/icons/facebook-icon';
import LinkedInIcon from 'src/assets/icons/linkedin-icon';
import TwitterIcon from 'src/assets/icons/twitter-icon';
import { Button } from 'src/components/button/Button';
import { CMS_THEME } from '../common/enums';
import RichText from '../components/rich_text/RichText';
import { theme } from '../theme/theme';

interface StorageDownloadShareWidgetProps {
  node: IStorageDownloadShareWidget;
  props: { location: typeof window.location; title?: string };
}

const DefaultSettings = {
  entity_heading: {
    element: 'h1',
    style: 'typography_h1',
  },
  entity_variant: {
    theme: CMS_THEME.LIGHT,
  },
};

const Wrapper = styled(Container)<{ cmsTheme: CMS_THEME }>`
  background-color: ${({ cmsTheme }) => theme[cmsTheme].background.default};
  color: ${({ cmsTheme }) => theme[cmsTheme].text.default};
`;

const StorageDownloadShareWidget: React.FC<StorageDownloadShareWidgetProps> = ({
  node: widget,
  props: { location, title } = {},
}) => {
  const settings = Object.assign(DefaultSettings, widget?.behaviors || {});
  const cmsTheme = settings.entity_variant.theme || CMS_THEME.LIGHT;
  const { element: HeadingElement, style: headingStyle } = settings.entity_heading;
  const { t } = useTranslation();
  const [currentURL, setCurrentURL] = useState('');
  useEffect(() => {
    const url = new URL(location?.href || process.env.GATSBY_SITE_URL);
    const urlSearchParams = url.searchParams;
    urlSearchParams.set('print', 'true');

    const updatedURL = `${url.origin}${url.pathname}?${urlSearchParams.toString()}`;
    setCurrentURL(updatedURL);
  }, [location?.search]);

  const emailSubject = title || 'Check out this page';

  const emailBody = `Take a look and print the information we discussed: ${currentURL}`;
  const mailtoLink = `mailto:?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(
    emailBody,
  )}`;

  return (
    <Wrapper
      data-id={widget.drupal_id}
      className="py-3 py-lg-5 px-0 no-print"
      fluid
      cmsTheme={cmsTheme}
    >
      <Container className="d-flex flex-column text-center align-items-center">
        {widget?.title && (
          <HeadingElement className={cx(headingStyle)}>{widget.title}</HeadingElement>
        )}
        {widget?.body?.processed && (
          <RichText cmsTheme={cmsTheme} className={cx('mt-4')} body={widget.body.processed} />
        )}
        <Button
          cmsTheme={cmsTheme}
          className="text-capitalize align-self-center justify-content-center text-center"
          style={{ maxWidth: 453 }}
          onClick={() => window?.print()}
          aria-label={t('Print')}
        >
          {t('Print')}
        </Button>
        <div className="pt-4">
          <a href={mailtoLink} aria-label={t('email')}>
            <EmailIcon fill="#424242" width="32" height="32" />
          </a>
        </div>

        <ul className="d-flex align-items-center py-5">
          <li className="">
            <a
              className="twitter-share-button"
              href={`https://twitter.com/intent/tweet?url=${currentURL}`}
              target="blank"
              aria-label={t('Twitter')}
            >
              <TwitterIcon fill="#424242" width="32" height="32" />
            </a>
          </li>
          <li className="px-5">
            <a
              className="linkin-share-button"
              href={`https://www.linkedin.com/shareArticle?mini=true&url=${currentURL}&title=${title}&summary=${emailSubject}&source=${currentURL}`}
              target="blank"
              aria-label={t('LinkedIn')}
            >
              <LinkedInIcon fill="#424242" width="32" height="32" />
            </a>
          </li>
          <li className="">
            <a
              className="facebook-share-button"
              href={`https://www.facebook.com/sharer/sharer.php?u=#${currentURL}`}
              target="blank"
              aria-label={t('Facebook')}
            >
              <FacebookIcon fill="#424242" width="32" height="32" />
            </a>
          </li>
        </ul>
      </Container>
    </Wrapper>
  );
};

export default StorageDownloadShareWidget;
