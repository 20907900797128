export interface ComponentColor {
  background: string;
  color: string;
}

export const componentColors = {
  light: {
    background: '#fff',
    color: '#2B2B2B',
  },
  light_red: {
    background: '#e91c24',
    color: '#fff',
  },
  cream: {
    background: 'rgba(225, 216, 183, .25)',
    color: '#2B2B2B',
  },
  slate_gray_full: {
    background: '#03202f',
    color: '#fff',
  },
  slate_gray_dark: {
    background: '#485463',
    color: '#fff',
  },
  dark: {
    background: '#485463',
    color: '#fff',
  },
  slate_gray_medium: {
    background: '#7d9aaa',
    color: '#fff',
  },
  neutral: {
    background: '#c3d3e5',
    color: '#2B2B2B',
  },
  slate_gray_light: {
    background: '#c3d3e5',
    color: '#2B2B2B',
  },
  yellow: {
    background: '#fdcc9b',
    color: '#2B2B2B',
  },
  purple: {
    background: '#ac5a88',
    color: '#fff',
  },
  green: {
    background: '#2dd7bd',
    color: '#2B2B2B',
  },
  blue: {
    background: '#3955c2',
    color: '#fff',
  },
  warm_gray: {
    background: '#aa9c8f',
    color: '#2B2B2B',
  },
};
