import * as React from 'react';
import { Row } from 'react-bootstrap';
import { Calories } from './FeedingCalculatorCalories';

interface FeedingCalculatorResultsProps {
  node: IStorageFeedingCalculator;
  weightGoalValue: string;
  calories: Calories | undefined;
}

const StorageFeedingCalculator = ({
  node,
  calories,
  weightGoalValue,
}: FeedingCalculatorResultsProps) => {
  const storage = node;

  return (
    <>
      <h2 className="h3 text-center">{storage.results.label}</h2>

      <Row className="bg-white text-center rounded-2 p-5 mb-3">
        <div className="resultsText">
          <h4>
            {calories?.idealWeight
              ? `${(Math.round(calories.idealWeight * 10) / 10).toFixed(1)}(${calories.unit})`
              : '--'}
          </h4>
          <h6>{storage.results.ideal_weight}</h6>
        </div>
      </Row>
      <Row className="bg-white text-center rounded-2 p-5">
        <div className="resultsText">
          {weightGoalValue === 'maintain' && (
            <h4>{calories?.maintain ? Math.round(calories.maintain).toLocaleString() : '--'}</h4>
          )}
          {weightGoalValue === 'lose' && (
            <h4>{calories?.lose ? Math.round(calories.lose).toLocaleString() : '--'}</h4>
          )}
          <h6>{storage.results.daily_caloric_needs_label}</h6>
        </div>
      </Row>
    </>
  );
};

export default StorageFeedingCalculator;
