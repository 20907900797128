import * as React from 'react';

interface ParagraphProps extends Paragraph {
  field_front_end_component: string;
}

interface ParagraphPlaceholderProps {
  node: ParagraphProps;
}

const ParagraphPlaceholder: React.FC<ParagraphPlaceholderProps> = function ({ node }) {
  const classes = node.behavior_settings?.decoupled_styles?.classes || [];
  return (
    <div
      className={classes && classes.join(' ')}
      dangerouslySetInnerHTML={{ __html: node.field_front_end_component }}
    />
  );
};

export default ParagraphPlaceholder;
