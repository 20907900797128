import React from 'react';

const EmailIcon = (props: any) => {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={props?.fill}
        d="M22 6.49V18a2 2 0 01-2 2H4a2 2 0 01-2-2V6.49L12 13l10-6.51zM20 4H4a4 4 0 00-4 4v12a4 4 0 004 4h16a4 4 0 004-4V8a4 4 0 00-4-4z"
      />
    </svg>
  );
};

export default EmailIcon;
