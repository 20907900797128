import { graphql, useStaticQuery } from 'gatsby';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import { styled } from '@linaria/react';
import * as React from 'react';
import Container from 'react-bootstrap/Container';
import { getParagraph } from '../utils/paragraphHelpers';
import { themes } from '../theme/colors';

const StyledFooter = styled.footer<Omit<FooterProps, 'data'>>`
  background-color: ${props => themes.purina.footerBg};
  color: #ffffff;
  border-top: 16px #e91c24 solid;

  & > .utility-footer {
    background-color: ${props => themes.purina.utilityBg};

    & ul > li {
      letter-spacing: 1px;
    }
  }
  @media (min-width: 992px) {
    & > .utility-footer ul > li:not(:first-child) {
      border-left: 1px solid #ffffff;
      padding: 0 0 0 0.2rem;
    }
  }

  ul {
    a {
      color: #ffffff;
    }
  }

  .logo {
    background-color: white;
    max-width: 193px;
    width: 100%;
    height: 65px;
    object-fit: cover;
  }

  .row-0 {
    background-color: #485463;
  }

  .row-2 {
    background-color: #03202f;
  }

  @media print {
    border-top: none;

    .logo {
      height: 100px;
    }

    .printFooter {
      page-break-inside: avoid !important;
    }

    .printFooter-bar {
      -webkit-print-color-adjust: exact !important; /* Chrome, Safari 6 – 15.3, Edge */
      color-adjust: exact !important; /* Firefox 48 – 96 */
      print-color-adjust: exact !important;
      background-color: #e91c24 !important;
      height: 24px;
      width: 100%;
    }
  }
`;

const PrintStyledText = styled.div`
  -webkit-print-color-adjust: exact !important; /* Chrome, Safari 6 – 15.3, Edge */
  color-adjust: exact !important; /* Firefox 48 – 96 */
  page-break-inside: avoid !important;
  print-color-adjust: exact !important;

  & > p {
    color: #ffffff !important;
    background-color: #e91c24 !important;
    border: 9px solid #e91c24 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    padding: 0.5rem !important;
    page-break-inside: avoid !important;
    margin-bottom: 0 !important;
  }
`;
interface FooterProps {
  theme: string;
  language: Languages;
}

interface DataProps {
  footer: {
    langcode: Languages;
    relationships: {
      rows: [Paragraph];
    };
  };
}

const Footer: React.FC<FooterProps> = function ({ language, theme }) {
  const data = useStaticQuery<DataProps>(graphql`
    {
      allStorageFooter {
        edges {
          node {
            langcode
            relationships {
              rows: field_rows {
                type: __typename
                ...ParagraphFooterRow
                ...ParagraphHorizontalRule
              }
            }
          }
        }
      }
    }
  `);

  const footer = data.allStorageFooter.edges.filter(({ node }) => node.langcode === language)[0];
  const rows = footer?.node?.relationships?.rows;

  const utilityFooter = rows?.find(
    row => row?.behavior_settings?.decoupled_styles?.classes?.includes('utility-footer'),
  );

  function getFooterLogoUrl(rows: any) {
    const paragraph__footer_row = rows?.find(row => row.type === 'paragraph__footer_row');
    const paragraph__linked_logo = paragraph__footer_row?.relationships?.columns?.find(
      r => r.type === 'paragraph__linked_logo',
    );

    const logoURL = paragraph__linked_logo?.relationships?.media?.relationships?.logo?.publicUrl;

    return logoURL;
  }

  const footerLogoUrl = getFooterLogoUrl(rows);

  return (
    <AnalyticsPoint
      type="module"
      typeLabel="footer"
      label="footer"
      as={StyledFooter}
      role="contentinfo"
      theme={theme}
    >
      <Container fluid className="no-print g-0">
        {!!rows &&
          rows.map((row, index) => {
            if (row === utilityFooter) return null;

            return (
              <React.Fragment key={row.id}>
                <Container fluid className={`no-print row-${index}`}>
                  <Container fluid="lg">
                    {getParagraph({ node: row, props: { location: 'footer' } })}
                  </Container>
                </Container>
              </React.Fragment>
            );
          })}
      </Container>

      <div className="utility-footer no-print">
        {utilityFooter && (
          <Container fluid="lg">
            {getParagraph({ node: utilityFooter, props: { location: 'footer' } })}
          </Container>
        )}
      </div>
      <div className="printFooter print-only">
        <PrintStyledText>
          <p>
            The Purina Institute aims to help put nutrition at the forefront of pet health
            discussions by providing user-friendly, science-based information that helps pets live
            longer, healthier lives.
          </p>
        </PrintStyledText>
        <div className="footer-logo">
          <img className="logo" src={footerLogoUrl} alt="Purina Logo" />
        </div>

        <div className="printFooter-bar" />
      </div>
    </AnalyticsPoint>
  );
};

export default Footer;
