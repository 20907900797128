import { PageProps, graphql } from 'gatsby';
import * as React from 'react';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import { getCmsComponent } from '../utils/cmsComponentHelpers';
import { getHelmetPropsFromMetatagProps, getJsonFromMetatagProps } from '../utils/metatagHelpers';

interface NodeResourceProps extends NodeContent {
  relationships?: {
    components: TCmsComponents[];
    category?: {
      name: string;
    }[];
    bucket?: {
      name: string;
    }[];
  };
}
interface DataProps {
  node: NodeResourceProps;
  translations: Translations;
}

const ResourceTemplate: React.FC<PageProps<DataProps>> = function ({
  data: { node, translations },
  location,
}) {
  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('print') === 'true') {
      window.print();
    }
  }, [location.search]);

  if (!node) return null;

  const components = node.relationships?.components;
  const category = node.relationships?.category?.[1]?.name;
  const bucket = node.relationships?.bucket?.[0]?.name;
  const helmetProps = getHelmetPropsFromMetatagProps(node.metatag);
  const schemaMarkup = getJsonFromMetatagProps(node.metatag);

  return (
    <Layout
      node={node}
      language={node.langcode}
      metaData={node.metatag}
      translations={translations}
    >
      <Seo {...helmetProps} schemaMarkup={schemaMarkup} />
      {components?.map((component, index) => {
        // eslint-disable-next-line
        return (
          <React.Fragment key={component.id}>
            {getCmsComponent({
              node: component,
              index,
              props: { category, location, title: node.title, bucket, components },
            })}
          </React.Fragment>
        );
      })}
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!, $language: String!, $nid: Int!) {
    node: nodeResource(id: { eq: $id }, langcode: { eq: $language }) {
      internal {
        type
      }
      id
      path {
        alias
      }
      default_langcode
      langcode
      title
      ...Metatag
      relationships {
        category: field_resource_category {
          name
        }
        bucket: field_resource_bucket {
          name
        }
        components: field_storage {
          type: __typename
          ...StorageFeedingCalculator
          ...StorageArticleCards
          ...StorageImageContent
          ...StorageHeroCard
          ...StorageManualCards
          ...StorageHighlights
          ...StorageRichText
          ...StoragePageTitle
          ...StorageDownloadShareWidget
          ...StorageBanner
          ...StorageBrandGrid
          ...StorageFaqSection
          ...StorageUnfilteredHtml
          ...StorageSubnav
          ...StorageCardGrid
          ...StorageRelatedContent
          ...StorageCategoryCards
          ...StorageContactCards
          ...StorageVideo
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    translations: allNodeResource(
      filter: { drupal_internal__nid: { eq: $nid }, langcode: { ne: $language } }
    ) {
      edges {
        node {
          langcode
          path {
            alias
          }
        }
      }
    }
  }
`;

export default ResourceTemplate;
