import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { getParagraph } from '../../utils/paragraphHelpers';

interface ParagraphFooterRowProps {
  node: ParagraphProps;
}

interface ParagraphProps extends Paragraph {
  relationships: {
    columns?: [Paragraph];
  };
}

const ParagraphFooterRow: React.FC<ParagraphFooterRowProps> = function ({ node }) {
  const columns = node.relationships?.columns;
  const classes = node.behavior_settings?.decoupled_styles?.classes || [];
  const wrapperClass = node.behavior_settings?.decoupled_styles?.wrapper;

  const Wrapper = wrapperClass === 'col' ? Col : Row;

  return (
    <Row>
      <Wrapper className={classes.join(' ')}>
        {columns?.map(column => (
          <React.Fragment key={column.id}>
            {getParagraph({ node: column, props: { location: 'footer' } })}
          </React.Fragment>
        ))}
      </Wrapper>
    </Row>
  );
};

export default ParagraphFooterRow;
