import SignUpIcon from 'src/assets/icons/signup-icon';
import VirtualTourIcon from 'src/assets/icons/virtual-tour-icon';

interface IconProps {
  className?: string;
}

export const getIcon = (text: string) => {
  const textToIcon: Record<string, (props: IconProps) => JSX.Element> = {
    'Virtual Tour': VirtualTourIcon,
    'Sign Up': SignUpIcon,
  };

  return textToIcon[text];
};
