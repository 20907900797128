import { styled } from '@linaria/react';
import React from 'react';
import { theme } from '../../theme/theme';

type Props = {
  onClick: React.MouseEventHandler;
  children: React.ReactNode;
};

const actionColor = theme.dark.action || theme.light.action;

export const ClearAllButton = styled.button`
  all: unset;
  color: ${actionColor};
  text-decoration: underline;
`;
const apiEndpoint = process.env.GATSBY_DRUPAL_SEARCH_ENDPOINT || '';

const ClearAll: React.FC<Props> = function ({ children, onClick }: Props) {
  const clearAllClick = (event: React.MouseEvent) => {
    event.preventDefault();
    onClick(event);
  };

  return (
    <ClearAllButton data-url={apiEndpoint} type="button" onClick={clearAllClick}>
      {children}
    </ClearAllButton>
  );
};

export default ClearAll;
