/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import 'bootstrap/dist/css/bootstrap.min.css';
import wrapWithProvider from './src/wrap-with-provider';

export const wrapRootElement = wrapWithProvider;

export const shouldUpdateScroll = ({
  prevRouterProps,
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const prevLocation = prevRouterProps?.location;

  if (
    prevLocation &&
    prevLocation.pathname === location.pathname &&
    prevLocation.search !== location.search
  ) {
    return false;
  }

  return window.scrollTo(0, 0);
};

// export const onRouteUpdateDelayed = () => {
//   window.___emitter.emit('routeDelayed');
// };
