import { PageProps, graphql } from 'gatsby';
import * as React from 'react';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import { getCmsComponent } from '../utils/cmsComponentHelpers';
import { getHelmetPropsFromMetatagProps, getJsonFromMetatagProps } from '../utils/metatagHelpers';

interface NodePageProps extends NodeContent {
  relationships?: {
    components: TCmsComponents[];
  };
}
interface DataProps {
  node: NodePageProps;
  translations: Translations;
}

const PageTemplate: React.FC<PageProps<DataProps>> = function ({ data: { node, translations } }) {
  const components = node.relationships?.components;
  const helmetProps = getHelmetPropsFromMetatagProps(node.metatag);
  const schemaMarkup = getJsonFromMetatagProps(node.metatag);

  return (
    <Layout
      node={node}
      language={node.langcode}
      metaData={node.metatag}
      translations={translations}
    >
      <Seo {...helmetProps} schemaMarkup={schemaMarkup} />
      {components &&
        components.map((component, index) => {
          // eslint-disable-next-line
          return (
            <React.Fragment key={component.id}>
              {getCmsComponent({ node: component, index, props: { components } })}
            </React.Fragment>
          );
        })}
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!, $language: String!, $nid: Int!) {
    node: nodePage(id: { eq: $id }, langcode: { eq: $language }) {
      internal {
        type
      }
      id
      path {
        alias
      }
      default_langcode
      langcode
      title
      ...Metatag
      relationships {
        components: field_storage {
          type: __typename
          ...StorageFeedingCalculator
          ...StorageArticleCards
          ...StorageImageContent
          ...StorageHeroCard
          ...StorageManualCards
          ...StorageHighlights
          ...StorageRichText
          ...StoragePageTitle
          ...StorageDownloadShareWidget
          ...StorageBanner
          ...StorageBrandGrid
          ...StorageFaqSection
          ...StorageUnfilteredHtml
          ...StorageSubnav
          ...StorageCardGrid
          ...StorageRelatedContent
          ...StorageCategoryCards
          ...StorageContactCards
          ...StorageVideo
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    translations: allNodePage(
      filter: { drupal_internal__nid: { eq: $nid }, langcode: { ne: $language } }
    ) {
      edges {
        node {
          langcode
          path {
            alias
          }
        }
      }
    }
  }
`;

export default PageTemplate;
