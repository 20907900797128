import { cx } from '@linaria/core';
import { styled } from '@linaria/react';
import React from 'react';
import { Container } from 'react-bootstrap';
import { CMS_THEME } from '../common/enums';
import { ButtonLink } from '../components/button/Button';
import { ComponentColor, componentColors } from '../theme/componentColors';

interface StorageBannerProps {
  node: IStorageBanner;
}

const DefaultSettings = {
  entity_variant: {
    justify_content: 'space-between',
    theme: CMS_THEME.LIGHT,
  },
  entity_heading: {
    element: 'h2',
    style: 'typography_h3',
  },
  entity_buttons: {
    primary_button: 'btn',
  },
};

const Wrapper = styled(Container)<{ componentColor: ComponentColor }>`
  background-color: ${({ componentColor }) => componentColor.background};
  color: ${({ componentColor }) => componentColor.color};

  @media print {
    padding-bottom: 0 !important;
    padding-top: 2rem !important;

    .container {
      max-width: 100% !important;
    }

    h2 {
      font-size: 14px !important;
      font-weight: 500;
      padding-bottom: 0 !important;
      padding-top: 0 !important;
    }
  }
`;

const StorageBanner: React.FC<StorageBannerProps> = ({ node: banner }) => {
  const settings = Object.assign(DefaultSettings, banner?.behaviors || {});
  const cmsTheme = settings.entity_variant.theme;
  const primaryButtonVariation = settings.entity_buttons.primary_button;
  const { element: HeadingElement, style: headingStyle } = settings.entity_heading;
  const contentJustify = settings.entity_variant.justify_content;
  const componentColor = componentColors[cmsTheme] || componentColors.light;

  return (
    <Wrapper data-id={banner.drupal_id} className="py-3 px-0" fluid componentColor={componentColor}>
      <Container
        className={cx('d-flex align-items-center gap-5', `justify-content-${contentJustify}`)}
      >
        {banner?.title ? (
          <HeadingElement className={cx(headingStyle, 'py-4', 'mb-0')}>
            {banner.title}
          </HeadingElement>
        ) : null}
        {banner?.link ? (
          <ButtonLink
            variant={primaryButtonVariation}
            to={banner.link.url}
            cmsTheme={cmsTheme}
            {...banner.link?.options?.attributes}
          >
            {banner.link.title ? banner.link.title : null}
          </ButtonLink>
        ) : null}
      </Container>
    </Wrapper>
  );
};

export default StorageBanner;
