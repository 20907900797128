module.exports = [{
      plugin: require('../plugins/gatsby-plugin-nerd-custom-page/gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Purina.com","short_name":"Purina","start_url":"/","background_color":"#e91c24","display":"minimal-ui","icon":"src/assets/media/images/favicon-512x512.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4ad9943a63c5d8afc4ecc9019555131c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","ja","es","fr","zh-hans","it","pt-br","de"],"defaultLanguage":"en","trailingSlash":"never","redirect":false,"i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false,"lowerCaseLng":true},"pages":[{"matchPath":"/:lang?","getLanguageFromPath":true},{"matchPath":"/:lang?/(.*)","getLanguageFromPath":true}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"xs":"(max-width: 575px)","sm":"(min-width: 576px)","md":"(min-width: 768px)","lg":"(min-width: 992px)","xl":"(min-width: 1200px)","portrait":"(orientation: portrait)"}},
    },{
      plugin: require('../plugins/gatsby-plugin-purina-analytics/gatsby-browser.tsx'),
      options: {"plugins":[],"recaptchaSiteKey":"setkey","gtmID":"GTM-KGQ2KV2","dataLayerName":"purData","siteID":"purinainstitute.com","routeChangeEventName":"page_view","onetrustID":"ebf6c5a1-81d1-4090-9ce2-35415e6d51f4-test","optimizeGTM":"OPT-MV444RW","optimizeUA":"UA-34990229-57","siteEnv":"prod"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
