import { cx } from '@linaria/core';
import { styled } from '@linaria/react';
import * as React from 'react';
import MegaMenu from './MegaMenu';
import { ParagraphProps as ParagraphMenu } from './ParagraphMenu';

const StyledMegaMenu = styled(MegaMenu)`
  .nav-link {
    font-size: 1.375rem;
  }
`;

interface ParagraphProps extends Paragraph {
  relationships: {
    menu: IStorageMegaMenu | IStorageMenu;
  };
}

interface ParagraphNavigationProps {
  node: ParagraphProps;
  props?: {
    showMenu: boolean;
    topMenuLinks?: ParagraphMenu;
  };
  translations?: Translations;
}

const ParagraphNavigation: React.FC<ParagraphNavigationProps> = function ({
  node,
  props,
  translations,
}) {
  const { menu } = node.relationships;
  const classes = node.behavior_settings?.decoupled_styles?.classes?.join(' ') || '';
  const offset = 95 + 48;
  if (menu.type === 'storage__mega_menu') {
    return (
      <StyledMegaMenu
        node={menu}
        offset={offset}
        className={cx(classes, 'col-nav', props?.showMenu && 'show')}
        topMenuLinks={props?.topMenuLinks}
        showMenu={props?.showMenu}
        setShowMenu={props.setShowMenu}
        translations={translations}
      />
    );
  }
  return null;
};

export default ParagraphNavigation;
