import React from 'react';

const VirtualTourIcon = (props: any) => (
  <svg
    width="30"
    height="27"
    viewBox="0 0 30 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.51582 3.36105C9.51582 3.05307 9.76187 2.7759 10.1002 2.7759H20.6192C20.9267 2.7759 21.2035 3.02228 21.2035 3.36105V10.66C22.0032 10.5368 22.6799 10.4136 23.2643 10.2596V2.31394C23.2643 1.45162 22.5569 0.743286 21.6649 0.743286H9.08522C8.22402 0.743286 7.5166 1.45162 7.5166 2.31394V10.2596C8.10099 10.3828 8.8084 10.5368 9.54657 10.6292V3.36105H9.51582Z"
      fill="white"
    />
    <path
      d="M21.1728 19.5914C21.1728 19.8993 20.9267 20.1765 20.5884 20.1765H10.1002C9.79263 20.1765 9.51581 19.9301 9.51581 19.5914V16.2037C8.83916 16.1113 8.1625 16.0189 7.48584 15.8957V24.6729C7.48584 25.5352 8.19325 26.2435 9.05446 26.2435H21.6341C22.5261 26.2435 23.2335 25.5352 23.2335 24.6729V15.8957C22.5569 16.0189 21.8494 16.1421 21.1728 16.2037V19.5914ZM15.3597 24.5189C14.6215 24.5189 14.0064 23.9338 14.0064 23.1946C14.0064 22.4555 14.6215 21.8703 15.3597 21.8703C16.0978 21.8703 16.6822 22.4555 16.6822 23.1946C16.6822 23.9338 16.0978 24.5189 15.3597 24.5189Z"
      fill="white"
    />
    <path
      d="M25.0173 6.22524V8.04227C25.0173 8.10387 25.048 8.13466 25.1096 8.16546C27.0473 8.7814 28.0007 9.48974 28.0007 9.8901C28.0007 10.3521 26.2783 11.8303 20.6498 12.5079L20.1269 12.5695C19.8808 12.6003 19.727 12.8158 19.7578 13.0622L19.9116 14.1401C19.9423 14.3865 20.1576 14.5405 20.4037 14.5097L20.9266 14.4481C23.664 14.1093 29.9999 13.0006 29.9999 9.8901C29.9999 8.16546 28.0622 6.93357 25.2018 6.10205C25.0788 6.10205 25.0173 6.16365 25.0173 6.22524Z"
      fill="white"
    />
    <path
      d="M12.2844 17.1274L13.0534 17.8973C13.2379 18.0821 13.5147 18.0821 13.6685 17.8973L17.4209 14.1401C17.6054 13.9553 17.6054 13.6781 17.4209 13.5241L13.6685 9.73607C13.484 9.55129 13.2071 9.55129 13.0534 9.73607L12.2844 10.506C12.0999 10.6908 12.0999 10.968 12.2844 11.1219L13.9453 12.785C6.56359 12.5694 2.71895 10.7216 2.71895 9.89006C2.71895 9.48969 3.70318 8.78136 5.64088 8.16542C5.70239 8.13462 5.73315 8.10382 5.73315 8.04223V6.22519C5.73315 6.1328 5.64088 6.07121 5.57936 6.10201C2.68819 6.93353 0.750488 8.16542 0.750488 9.89006C0.750488 13.0314 7.39404 14.602 14.0068 14.7868L12.2844 16.5114C12.0999 16.6654 12.0999 16.9426 12.2844 17.1274Z"
      fill="white"
    />
  </svg>
);

export default VirtualTourIcon;
