import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import React, { useContext } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import CardSkeleton from '../card_skeleton/CardSkeleton';
import StyledCard from '../styledcard/StyledCard';
import NoResults from './NoResults';
import { SearchListingContext } from './SearchListingContext';

interface Props {
  serverData?: DrupalSearch;
}

const ResourceSearchResults: React.FC<Props> = function ({ serverData }) {
  const context = useContext(SearchListingContext);

  if (!context && !serverData) {
    throw new Error(
      'Child components of SearchListing cannot be rendered outside the SearchListing component!',
    );
  }

  const { data, loaded } = context || {};
  const search_results = serverData?.search_results || data?.search_results || ({} as DrupalSearch);
  if (!loaded) {
    return (
      <Row className="mb-5 gy-3 gy-md-4 gx-2 gx-md-3">
        {[...Array(12)].map((_, i) => {
          return (
            <Col key={`loading-card-${i}`} xs={6} md={4} className="d-flex">
              <CardSkeleton />
            </Col>
          );
        })}
      </Row>
    );
  }

  if (!search_results) {
    return <NoResults />;
  }

  return (
    <Row id="search-results" className="mb-5 gy-3 gy-md-4 gx-2 gx-md-3">
      {Object.values(search_results).map(node => {
        const image = node.image_uri
          ? process.env.GATSBY_DRUPAL_ENDPOINT + node.image_uri.substring(1)
          : '';

        return (
          <Col key={node.nid} xs={12} md={6} lg={4} className="d-flex">
            <AnalyticsPoint
              type="component"
              category="search_result_selected"
              action={node.title}
              eventLabel="resources"
              node={{ ...node }}
              className="w-100"
            >
              <StyledCard
                body={node.description}
                image={{
                  images: {
                    fallback: {
                      src: image,
                    },
                  },
                  layout: 'fullWidth',
                  width: 400,
                  height: 250,
                }}
                duration={node.read_watch_time}
                link={node.url}
                title={node.title}
                topic={node.category}
                buttonText={node.button_label}
              />
            </AnalyticsPoint>
          </Col>
        );
      })}
    </Row>
  );
};

ResourceSearchResults.displayName = 'SearchListing.ResourceSearchResults';

export default ResourceSearchResults;
