import React from 'react';

const SignUpIcon = (props: any) => (
  <svg
    width="22"
    height="23"
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_582_17262)">
      <path d="M12.27 3.93341L0 16.2034V22.4934H6.29L18.56 10.2234L12.27 3.93341Z" fill="white" />
      <path
        d="M13.6699 2.53341L19.9599 8.82341L21.9999 6.78341L15.7099 0.493408L13.6699 2.53341Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_582_17262">
        <rect width="22" height="22" fill="white" transform="translate(0 0.493408)" />
      </clipPath>
    </defs>
  </svg>
);

export default SignUpIcon;
