import { common } from './common';
import { ITheme } from './types';

export const slate_gray_medium: ITheme = {
  mode: 'dark',
  primary: {
    main: '#FFF',
    withAlpha: (alpha: number) => `rgba(255, 255, 255, ${alpha})`,
    // dark: '',
    // light: '',
  },
  text: {
    invert: '#2B2B2B',
    withAlpha: (alpha: number) => `rgba(255, 255, 255, ${alpha})`,
    default: '#FFF',
    hover: '#e91c24',
  },
  button: {
    primary: {
      background: '#e91c24',
      text: '#FFF',
      withAlpha: (alpha: number) => '#FFF',
      border: '1px solid #e91c24',
    },
    secondary: {
      background: '#FFF',
      text: '#FFF',
      withAlpha: (alpha: number) => `rgba(255, 255, 255, ${alpha})`,
    },
  },
  background: {
    default: '#1E1E1E',
    alternate: '#7d9aaa',
    paper: '#464646',
    imageMatte: '#EDF1FA',
    iconContainer: '#FFF',
  },
  action: '#e91c24',
  ...common,
};
