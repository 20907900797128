import { styled } from '@linaria/react';
import React, { useContext } from 'react';
import { Spinner } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import AccordionItem from 'react-bootstrap/AccordionItem';
import { getActiveFacetCount } from '../../utils/helpers';
import FacetItem from '../facets/FacetItem';
import { SearchListingContext } from './SearchListingContext';

interface FacetProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const FacetCount = styled.span`
  align-items: center;
  border-radius: 50%;
  background-color: #edf0f8;
  display: inline-flex;
  margin-left: auto;
  height: 1.75rem;
  justify-content: center;
  width: 1.75rem;
`;

const FacetList = styled.ul`
  padding-left: 0;

  li {
    list-style: none;
  }
`;

const SearchFacets: React.FC<FacetProps> = function (props) {
  const context = useContext(SearchListingContext);

  if (!context) {
    throw new Error(
      'Child components of SearchListing cannot be rendered outside the SearchListing component!',
    );
  }

  const { id, data, loaded } = context || {};
  const { facets } = data || {};

  if (!loaded) {
    return (
      <>
        <span aria-live="polite" className="visually-hidden">
          Loading...
        </span>
        <Spinner animation="border" role="status" />
      </>
    );
  }

  if (!facets) {
    return null;
  }

  const getActiveAccordions = () => {
    const facetsUsed = Object.values(facets);

    // it will default to those values initially only if they truly exist within the facets
    const activeAccordions: string[] = [
      `${id}-facets-field_category`,
      `${id}-facets-field_species_term`,
    ];

    facetsUsed.forEach(facet => {
      const found = (facet?.facets || []).some(item => item.values.active);
      if (found) {
        activeAccordions.push(`${id}-facets-${facet.field_id}`);
      }
    });

    return activeAccordions;
  };

  return (
    <Accordion defaultActiveKey={getActiveAccordions()} id={`${id}-facets`}>
      <span aria-live="polite" className="visually-hidden">
        Loading complete
      </span>
      {Object.values(facets).map((facet: any) => {
        if (facet?.facets?.length > 0) {
          const activeCount = getActiveFacetCount(facet);
          return (
            <AccordionItem
              key={`${id}-facets-${facet.field_id}`}
              eventKey={`${id}-facets-${facet.field_id}`}
            >
              <Accordion.Header id={facet.field_id}>
                {facet.label}
                {activeCount > 0 && (
                  <FacetCount
                    aria-label={`${activeCount} ${facet.label} facets selected.`}
                    className="count"
                  >
                    {activeCount}
                  </FacetCount>
                )}
              </Accordion.Header>
              <Accordion.Body>
                <FacetList role="group" aria-labelledby={facet.field_id}>
                  {/* facet is the actual facet (category, brand, etc). */}
                  {/* facet.facets refers to the array of items belonging to the facet (dry dog food, cat shampoo, etc). */}
                  {facet.facets.map((facetItem: FacetLink, i: number) => {
                    if (facetItem && id) {
                      return (
                        <li key={`${id}-facets-${facet.field_id}-${i}`}>
                          <FacetItem
                            id={`${id}-facets-${facet.field_id}`}
                            onChange={props.onChange}
                            facet={facetItem}
                          />
                        </li>
                      );
                    }
                    return null;
                  })}
                </FacetList>
              </Accordion.Body>
            </AccordionItem>
          );
        }
        return null;
      })}
    </Accordion>
  );
};

SearchFacets.displayName = 'SearchListing.SearchFacets';

export default SearchFacets;
