import { AxiosError } from 'axios';
import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import StickyFacetContainer from 'src/components/search_listing/StickyFacetContainer';
import SearchListing from '../../components/search_listing/SearchListing';
import { handleFacetChange, handleSortChange } from '../../utils/helpers';

interface Base {
  error: unknown;
  location: Location;
  contextualFilters?: SearchParams;
}

interface Api extends Base {
  data: DrupalSearch | undefined;
  serverData?: DrupalSearch;
}

interface Server extends Base {
  data: DrupalSearch | undefined;
  serverData: DrupalSearch;
}

const EventListingBody: React.FC<Server | Api> = ({
  data,
  serverData,
  error,
  location,
  contextualFilters,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const params = new URLSearchParams(location.search);
  const sortBy = params.get('sort_by');

  const sortOptions = [
    {
      label: t('Newest to Oldest'),
      value: 'latest',
    },
    {
      label: t('Relevance'),
      value: 'relevance',
    },
    {
      label: t('PI Event Duration'),
      value: 'duration',
    },
    {
      label: t('Title'),
      value: 'title',
    },
  ];

  useEffect(() => {
    setIsLoading(false);
  }, [location]);
  return (
    <Container className="my-5">
      <SearchListing
        data={data}
        error={(error as AxiosError) || new Error(t('Sorry, there was an error'))}
        loaded={!isLoading}
        defaultLoadedState
      >
        <Row className="align-items-center py-3">
          <SearchListing.SearchHeader
            title={t('Events')}
            onFacetRemove={e => handleFacetChange(e, location, navigate, contextualFilters)}
          />
        </Row>
        <Row className="position-relative">
          <Col lg={3} className="py-3 d-none d-lg-block">
            <StickyFacetContainer>
              <SearchListing.Sorting
                options={sortOptions}
                initialValue={sortBy}
                onChange={e => handleSortChange(e, location, navigate)}
              />
              <SearchListing.SearchFacets
                onChange={e => handleFacetChange(e, location, navigate, contextualFilters)}
              />
            </StickyFacetContainer>
          </Col>
          <Col>
            <SearchListing.EventSearchResults serverData={serverData} />
            <SearchListing.Pagination serverData={serverData} />
          </Col>
          <SearchListing.FacetModal
            onChange={e => handleFacetChange(e, location, navigate, contextualFilters)}
          />
        </Row>
      </SearchListing>
    </Container>
  );
};
export default EventListingBody;
