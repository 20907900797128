import { Dispatch, SetStateAction } from 'react';

export const camelToKebab = (string: string) =>
  string.replace(/[A-Z]/g, m => `-${m.toLowerCase()}`);

export const prepareAttribute = (string: string) => {
  const camelCandidates = ['aria', 'data'];
  if (camelCandidates.includes(string.substring(0, 4))) {
    return camelToKebab(string);
  }

  return string;
};

// Function to validate if the brand has a valid path
const validatePathForBrand = (brand: TaxonomyTerm): boolean => {
  return Boolean(brand.relationships?.field_page_delegate?.path?.alias && brand.name);
};

export const getBrand = (brand: TaxonomyTerm): { url: string; title: string } | null => {
  let alias = brand.relationships?.field_page_delegate?.path?.alias;
  let title = brand.name;

  // Check if brand has a parent
  const brandParent = brand.relationships?.parent;
  if (brandParent && brandParent.length) {
    // Check if parent has a valid path
    const parentBrand = brandParent[0];
    if (validatePathForBrand(parentBrand)) {
      alias = parentBrand.relationships.field_page_delegate.path.alias;
      title = parentBrand.name;
    }

    // Check if parent has a grandparent with a valid path
    const brandGrandparent = parentBrand.relationships?.parent;
    if (brandGrandparent?.length) {
      const grandparentBrand = brandGrandparent[0];
      if (validatePathForBrand(grandparentBrand)) {
        alias = grandparentBrand.relationships.field_page_delegate.path.alias;
        title = grandparentBrand.name;
      }
    }
  }

  if (!alias) {
    return null;
  }

  return {
    url: encodeURI(alias),
    title,
  };
};

export const formatSku = (sku: string) => {
  const formattedSku = sku.length > 10 ? sku.substring(1, 11) : sku;
  return formattedSku;
};

export const getActiveFacetCount = (facet: Facet) => {
  let count = 0;
  if (facet?.facets) {
    facet.facets.forEach(facetLink => {
      if (facetLink.values?.active) {
        count++;
      } else if (facetLink?.children) {
        facetLink.children[0].forEach(child => {
          if (child.values?.active) {
            count++;
          } else if (child?.children) {
            child.children[0].forEach(grandChild => {
              if (grandChild.values?.active) {
                count++;
              }
            });
          }
        });
      }
    });
  }

  return count;
};

const isObject = (value: any) => {
  return !!(value && typeof value === 'object' && !Array.isArray(value));
};

export const getActiveFacets = (object: FacetLink | FacetLink[]) => {
  const matches: FacetLink[] = [];
  if (isObject(object)) {
    const entries = Object.entries(object);

    for (let i = 0; i < entries.length; i += 1) {
      const [objectKey, objectValue] = entries[i];

      if (objectKey === 'values' && objectValue?.active === 'true') {
        matches.push(object as FacetLink);
      }

      if (isObject(objectValue)) {
        const child = getActiveFacets(objectValue);
        if (child) {
          matches.push(...child);
        }
      }

      if (Array.isArray(objectValue)) {
        objectValue.forEach(item => {
          const child = getActiveFacets(item);
          if (child) {
            matches.push(...child);
          }
        });
      }
    }
  }

  if (Array.isArray(object)) {
    object.forEach(item => {
      const child = getActiveFacets(item);
      if (child) {
        matches.push(...child);
      }
    });
  }

  return matches;
};

export const processDrupalSearchResponse = (
  data: DrupalSearch & { facets_metadata?: DrupalSearch['facets'] },
): DrupalSearch => {
  if (!data.facets || !data.facets_metadata) {
    return data;
  }
  const facetMetaArray = Object.entries(data.facets_metadata);
  const facets: Facets = { ...data.facets_metadata };
  Object.values(data.facets).forEach((facet: any) => {
    const key: string = Object.keys(facet[0])[0];
    let match = null;

    facetMetaArray.forEach((facetMeta: any) => {
      if (facetMeta[1].field_id === key) {
        match = facetMeta[0];
      }
    });

    if (match) {
      facets[match] = { ...facets[match], facets: facet[0][key] };
    }
  });

  // Process search_results bazaarvoice_id has it's value processed to include hyphen.
  const searchResults = data.search_results?.map((result: any) => {
    const newResult = { ...result };
    if (newResult.bazaarvoice_id) {
      newResult.bazaarvoice_id = `${newResult.bazaarvoice_id.substring(
        0,
        5,
      )}-${newResult.bazaarvoice_id.substring(5)}`;
    }
    return newResult;
  });

  return {
    search_results: searchResults,
    facets,
    pager: data.pager,
    did_you_mean: data.did_you_mean,
    params: data?.params,
  };
};

export const keysOf = <T extends Object>(obj: T): Array<keyof T> => {
  return Array.from(Object.keys(obj)) as any;
};

export const getNestedValues = (data: any[], values: any[], needle: string) => {
  // If object.
  if (!(data instanceof Array) && typeof data === 'object') {
    Object.keys(data).forEach(key => {
      if (typeof data[key] === 'object') {
        getNestedValues(data[key], values, needle);
      } else if (key === needle) {
        values.push(data[key]);
      }
    });
  }

  // If array.
  if (data instanceof Array) {
    data.forEach((item, i) => {
      if (typeof data[i] === 'object') {
        getNestedValues(data[i], values, needle);
      }
    });
  }

  return values;
};

export const formatPhoneNumber = (value?: string) => {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, '');
  const phoneNumberLength = phoneNumber.length;

  if (phoneNumberLength < 4) return phoneNumber;

  if (phoneNumberLength < 7) return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;

  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

export const handleFacetChange: any = (
  event: React.ChangeEvent<HTMLInputElement>,
  location: any,
  navigate: any,
  contextualFilters?: SearchParams,
  setIsLoading?: Dispatch<SetStateAction<boolean>>,
) => {
  if (setIsLoading) {
    setIsLoading(true);
  }

  const { url } = event.currentTarget.dataset;
  if (url) {
    const facetUrlObject = new URL(url);
    const facetSearchParams = new URLSearchParams(facetUrlObject.search);
    if (contextualFilters) {
      Object.keys(contextualFilters).forEach(key => facetSearchParams.delete(key));
    }
    if (facetSearchParams.toString() !== new URLSearchParams(location.search).toString()) {
      navigate(`?${facetSearchParams}`);
    }
  }
};

export const handlePagination = (
  _event: React.MouseEvent,
  page: number,
  location: any,
  navigate: any,
) => {
  const queryParams = new URLSearchParams(location.search);
  if (page === 0) {
    queryParams.delete('page');
  } else {
    queryParams.set('page', `${page}`);
  }
  navigate(`?${queryParams}`);
};

export const handleSortChange = (
  event: React.ChangeEvent<HTMLSelectElement>,
  location: any,
  navigate: any,
) => {
  const queryParams = new URLSearchParams(location.search);
  queryParams.set('sort_by', event.target.value);
  navigate(`?${queryParams}`);
};

/**
 * Finds the closest parent or parent with the longest nesting depth relative to a given url.
 * compares the url provided against the list of urls and returns
 * the url that has the highest nesting depth (most /) that has the same root as the url.
 * If the url given is in the urls list it will be selected.
 **/
export function findClosestParentURL(url: string, urls: LinkProps[]) {
  let closestParentURL = '';
  let parentDepth = 0;

  // eslint-disable-next-line
  for (const u of urls) {
    if (url.startsWith(u.url)) {
      const parentCount = u.url.split('/').filter(p => p).length;
      if (parentCount > parentDepth) {
        parentDepth = parentCount;
        closestParentURL = u.url;
      }
    }
  }

  return closestParentURL;
}

export function pollObjectOnWindow(
  objectName: string,
  interval: number,
  maxAttempts: number,
  attempts: number = 0,
): Promise<any> {
  return new Promise((resolve, reject) => {
    if (window[objectName]) {
      resolve(window[objectName]);
    } else if (maxAttempts && attempts >= maxAttempts) {
      reject(new Error(`Failed to find object '${objectName}' on the window.`));
    } else {
      setTimeout(() => {
        pollObjectOnWindow(objectName, interval, maxAttempts, attempts + 1)
          .then(resolve)
          .catch(reject);
      }, interval);
    }
  });
}

// in case we decide to explicitly hide Lang Switcher

const paths = [
  '/search/events',
  '/events',
  'events/all-events',
  '/events/upcoming-events',
  '/about-us/our-partnerships',
  '/about-us/our-partnerships/*',
  '/virtual-exhibit',
];

export function hideLangSwitcher(url: string): boolean {
  // Return false if the url starts with any value in the paths array
  return !paths.some(path => url.startsWith(path));
}
