import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import React, { Suspense } from 'react';

interface ComponentsProps {
  [index: string]: React.FC<any>;
}

const components: ComponentsProps = {
  storage__article_cards: React.lazy(() => import('../cms-components/StorageArticleCards')),
  storage__image_content: React.lazy(() => import('../cms-components/StorageImageContent')),
  storage__hero_card: React.lazy(() => import('../cms-components/StorageHeroCard')),
  storage__manual_cards: React.lazy(() => import('../cms-components/StorageManualCards')),
  storage__highlights: React.lazy(() => import('../cms-components/StorageHighlights')),
  storage__rich_text: React.lazy(() => import('../cms-components/StorageRichText')),
  storage__page_title: React.lazy(() => import('../cms-components/StoragePageTitle')),
  storage__download_share_widget: React.lazy(
    () => import('../cms-components/StorageDownloadShareWidget')
  ),
  storage__banner: React.lazy(() => import('../cms-components/StorageBanner')),
  storage__brand_grid: React.lazy(() => import('../cms-components/StorageBrandGrid')),
  storage__faq_section: React.lazy(() => import('../cms-components/StorageFaqSection')),
  storage__html: React.lazy(() => import('../cms-components/StorageUnfilteredHtml')),
  storage__subnav: React.lazy(() => import('../cms-components/StorageSubnav')),
  storage__card_grid: React.lazy(() => import('../cms-components/StorageCardGrid')),
  storage__related_content: React.lazy(() => import('../cms-components/StorageRelatedContent')),
  storage__category_cards: React.lazy(() => import('../cms-components/StorageCategoryCards')),
  storage__contact_cards: React.lazy(() => import('../cms-components/StorageContactCards')),
  storage__video: React.lazy(() => import('../cms-components/StorageVideo')),
  storage__feeding_calculator: React.lazy(() => import('../cms-components/StorageFeedingCalculator')),
};

interface Props {
  [key: string]: any;
}
interface ICmsComponentProps {
  node: IStorage | TCmsComponents;
  index: number;
  props?: Props;
}

export const getCmsComponent = ({
  node,
  index,
  props,
}: ICmsComponentProps): React.ReactElement | null => {
  if (components.hasOwnProperty(node.type)) {
    const CmsComponent = components[node.type];
    // eslint-disable-next-line
    node.indexOnPage = index;
    return (
      <Suspense fallback={<>Loading ${node.type}...</>}>
        <AnalyticsPoint type="module" node={node}>
          <CmsComponent node={node} props={props} />
        </AnalyticsPoint>
      </Suspense>
    );
  }
  return null;
};
