import { useLocation } from '@reach/router';
import { GatsbyImage } from 'gatsby-plugin-image';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import { styled } from '@linaria/react';
import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { findClosestParentURL } from 'src/utils/helpers';
import { CMS_THEME } from '../common/enums';
import Link from '../components/Link';
import HorizontalScroller from '../components/horizontal_scroller/HorizontalScroller';
import { theme } from '../theme/theme';
import { getFocalPoint, getMedia } from '../utils/mediaHelpers';

interface ContainerProps {
  cmsTheme: CMS_THEME;
  justify?: string;
  hasImage?: boolean;
}

enum LogoTypes {
  Text = 'text',
  Image = 'image',
}

const SubnavContainer = styled(Container)<ContainerProps>`
  width: 100%;
  height: ${props => (props.hasImage ? '400px' : '100px')};
  padding: 0;
  position: relative;

  .subnav-scroller {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 100%;
  }

  .hs__scroll-container {
    justify-content: center;
  }

  .subnav__item {
    padding: 10px 25px;
    text-transform: capitalize;
    text-decoration: none;
    position: relative;
    color: ${props => (props.cmsTheme === 'dark' ? '#FFF' : '#000')};

    &:hover {
      color: ${props => (props.cmsTheme === 'dark' ? '#FFF' : '#000')};
      background: ${({ cmsTheme }) =>
        cmsTheme === 'dark' ? 'rgba(255, 255, 255, 0.4)' : 'rgba(150, 150, 150, 0.65)'};
    }

    &:focus-visible {
      outline-offset: -3px;
    }

    &.subnav-active::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 3px;
      background: ${props => (props.cmsTheme === 'dark' ? '#FFF' : '#000')};
    }
  }
`;

const ImageContainer = styled.div`
  max-width: 500px;
  max-height: 300px;

  img {
    width: 100%;
  }
`;

const ContentContainer = styled(Container)<ContainerProps>`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: ${({ cmsTheme }) =>
    cmsTheme === 'dark' ? 'rgba(43, 43, 43, 0.65)' : 'rgba(255, 255, 255, 0.6)'};
  display: flex;
  justify-content: ${props => props.justify || 'center'};
  align-items: center;

  .caption {
    position: absolute;
    bottom: 50px;
    right: 0;
    padding: 10px 25px;
    text-transform: uppercase;
    text-decoration: none;
    color: ${props => (props.cmsTheme === 'dark' ? '#FFF' : '#000')};
  }
`;

const TextContainer = styled(Container)<{ cmsTheme: CMS_THEME; justify: string }>`
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify};
  color: ${({ cmsTheme }) => theme[cmsTheme].text.default};
`;

const StyledGatsbyImage = styled(GatsbyImage)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 0;
`;

type Props = {
  node: IStorageSubnav;
};

const StorageSubnav = ({ node: subnav }: Props) => {
  const cmsTheme = subnav?.behaviors?.entity_variant?.theme || CMS_THEME.DARK;
  const contentJustify = subnav?.behaviors?.entity_variant?.justify_content || 'center';
  const bgImage = subnav.relationships.background_image[0];
  const logo = subnav.relationships.secondary_logo;
  const textLogo = subnav.secondary_text?.processed;
  const logoType = subnav.logo_type;
  const { links, caption } = subnav;
  const location = useLocation();
  const [activeLink] = useState(findClosestParentURL(location.pathname, links));

  return (
    <SubnavContainer
      data-id={subnav.drupal_id}
      cmsTheme={cmsTheme}
      hasImage={!!bgImage}
      fluid
      className="no-print"
    >
      {bgImage && (
        <StyledGatsbyImage
          alt={bgImage.field_media_image?.alt}
          image={bgImage.relationships.field_media_image?.gatsbyImage}
          objectFit="cover"
          objectPosition={getFocalPoint(subnav.relationships.background_image)}
        />
      )}
      <ContentContainer cmsTheme={cmsTheme} justify={contentJustify} fluid>
        {logoType === LogoTypes.Image ? (
          <ImageContainer>{logo && getMedia({ media: logo, width: 400 })}</ImageContainer>
        ) : (
          <TextContainer cmsTheme={cmsTheme} justify={contentJustify}>
            {textLogo && <h1 className="typography_h1"> {textLogo}</h1>}
          </TextContainer>
        )}
        {!!caption && (
          <div className="caption">
            <p>{caption}</p>
          </div>
        )}
      </ContentContainer>
      <nav aria-label={subnav.name}>
        <HorizontalScroller
          className="subnav-scroller"
          activeClass=".subnav-active"
          cmsTheme={cmsTheme}
        >
          {links.map(link => (
            <AnalyticsPoint
              type="component"
              node={{ title: link.title, drupal_id: '', name: link.title, type: 'subnav_item' }}
              as={Link}
              key={link.title}
              className={`subnav__item ${link.url === activeLink ? 'subnav-active' : ''}`}
              to={link.url}
              {...link.options?.attributes}
            >
              <span dangerouslySetInnerHTML={{ __html: link.title }} />
            </AnalyticsPoint>
          ))}
        </HorizontalScroller>
      </nav>
    </SubnavContainer>
  );
};

export default StorageSubnav;
