import { GatsbyImage } from 'gatsby-plugin-image';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import { cx } from '@linaria/core';
import { styled } from '@linaria/react';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ButtonDiadWrapper from 'src/components/button/ButtonDiadWrapper';
import { CMS_THEME } from '../common/enums';
import { ButtonLink } from '../components/button/Button';
import { theme } from '../theme/theme';
import RichText from 'src/components/rich_text/RichText';

const ImageContentContainer = styled(Container)<{ cmsTheme: CMS_THEME }>`
  background-color: ${({ cmsTheme }) => theme[cmsTheme].background.default};
  color: ${({ cmsTheme }) => theme[cmsTheme].text.default};

  img {
    max-width: 100%;
  }

  @media print {
    padding-top: 0 !important;

    & > .container {
      max-width: 100% !important;
    }

    .col {
      width: 30% !important;
      display: flex;
      align-items: center;
    }

    .flex-column {
      width: 70% !important;
    }

    font-size: 1rem;

    h1 {
      font-size: 44px !important;
      font-weight: 700;
      margin-bottom: 16px !important;
      margin-top: 16px !important;
    }

    &.is-article > p:first-of-type {
      font-size: 1.2rem;
    }

    p,
    a,
    sup {
      color: #e91c24 !important;
      font-size: 0.8rem !important;
      line-height: 1.6 !important;
    }

    li {
      font-size: 0.8rem !important;
      line-height: 1.6 !important;
    }
  }
`;

interface StorageImageContentProps {
  node: IStorageImageContent;
}

const DefaultSettings = {
  entity_buttons: {
    primary_button: 'btn',
  },
  entity_heading: {
    element: 'h2' as keyof JSX.IntrinsicElements,
    style: 'typography_h2',
  },
  entity_subheading: {
    element: 'h3' as keyof JSX.IntrinsicElements,
    style: 'typography_h3',
  },
  entity_variant: {
    content_position: 'right',
    theme: CMS_THEME.LIGHT,
  },
};

const StorageImageContent: React.FC<StorageImageContentProps> = function ({ node: imageContent }) {
  const settings = Object.assign(DefaultSettings, imageContent?.behaviors || {});
  const cmsTheme = settings.entity_variant.theme;
  const primaryButtonVariation = settings.entity_buttons?.primary_button;
  const { element: HeadingElement, style: headingStyle } = settings.entity_heading;
  const { element: SubheadingElement, style: subheadingStyle } = settings.entity_subheading;
  const images = imageContent.relationships?.images;

  return (
    <ImageContentContainer
      data-id={imageContent.drupal_id}
      className={cx('py-3 py-md-5 px-0')}
      fluid
      cmsTheme={cmsTheme}
    >
      <Container>
        <Row
          className={cx(
            settings?.entity_variant.content_position === 'right' ? 'flex-row-reverse' : null,
          )}
        >
          <Col>
            {/* there can be multiple images but we only want to display the first image caption and the last image credit */}
            <p>{!!images?.length && images[0].field_caption}</p>
            {images &&
              images.map((media_image: MediaImage, i: number) => {
                return (
                  <GatsbyImage
                    key={i}
                    alt={media_image?.field_media_image?.alt}
                    image={media_image.relationships?.field_media_image?.gatsbyImage}
                    className="rounded-2 mb-3 mb-md-0"
                  />
                );
              })}
            <p className="mt-3 mb-0">
              {!!images?.length && images[images.length - 1].field_credit}
            </p>
          </Col>
          <Col xs={12} md={6} className="card-content d-md-flex flex-column justify-content-center">
            {imageContent.title && (
              <HeadingElement className={headingStyle}>{imageContent.title}</HeadingElement>
            )}
            {imageContent.subtitle && (
              <SubheadingElement className={subheadingStyle}>
                {imageContent.subtitle}
              </SubheadingElement>
            )}
            {imageContent.body?.processed && (
              <RichText className="mt-3" cmsTheme={cmsTheme} body={imageContent.body.processed} />
            )}
            {imageContent.links && imageContent.links?.length > 0 && (
              <ButtonDiadWrapper>
                {imageContent.links.map((linkObject, index) => {
                  return (
                    <AnalyticsPoint
                      type="component"
                      node={{
                        title: linkObject.title,
                        name: linkObject.title,
                        drupal_id: '',
                        type: 'image_content_link',
                      }}
                      as={ButtonLink}
                      key={index}
                      variant={index === 0 ? primaryButtonVariation : 'btn-outline'}
                      cmsTheme={cmsTheme}
                      to={linkObject.url}
                      {...linkObject.options?.attributes}
                    >
                      {linkObject.title}
                    </AnalyticsPoint>
                  );
                })}
              </ButtonDiadWrapper>
            )}
          </Col>
        </Row>
      </Container>
    </ImageContentContainer>
  );
};

export default StorageImageContent;
