import { PageProps, graphql } from 'gatsby';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { css } from '@linaria/core';
import * as React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import HorizontalScroller from 'src/components/horizontal_scroller/HorizontalScroller';
import Layout from '../components/Layout';
import Link from '../components/Link';
import Seo from '../components/Seo';
import ArticleOverlayCard from '../components/article_card/ArticleOverlayCard';
import RichText from '../components/rich_text/RichText';
import { theme } from '../theme/theme';
import { getFocalPoint, getMedia } from '../utils/mediaHelpers';
import { getHelmetPropsFromMetatagProps, getJsonFromMetatagProps } from '../utils/metatagHelpers';

interface DataProps {
  node: IArticleContent;
  relatedArticles: any;
  backfillArticles: any;
  translations: Translations;
}

const chip = css`
  background-color: white;
  border: 1px solid #b6bed2;
  border-radius: 0.5rem;
  color: #000000;
  font-size: 1.125rem;
  font-weight: 500;
  letter-spacing: 0.15em;
  margin-left: 1rem;
  padding: 0.5em 1em;
  text-decoration: none;
  text-transform: uppercase;

  &:first-of-type {
    margin-left: 0;
  }

  &:hover {
    border-color: #000000;
    color: #000000;
  }
`;

const relatedLayout = css`
  display: grid;
  gap: 1rem;
  grid-template-columns: 100%;
  grid-template-rows: auto;

  @media (min-width: ${theme.media.md}) {
    grid-template-columns: 1fr 1fr;

    & > :first-child {
      grid-column: 1 / 3;
      grid-row: 1;
    }
  }

  @media (min-width: 960px) {
    grid-template-columns: 66% 1fr;
    grid-template-rows: auto;
    grid-auto-flow: column;

    & > :first-child {
      grid-column: 1;
      grid-row: 1 / 3;

      & > * {
        height: 100%;
      }
    }
  }
`;
const getArticleCardProps = (node: IArticleContent) => {
  const { title, drupal_id } = node;
  const summary = node.body.summary ?? undefined;
  const url = node.path?.alias;
  // eslint-disable-next-line
  const image = node.relationships.field_image?.relationships.field_media_image?.gatsbyImage;

  const imageField = node.relationships.field_image;
  const imageAlt = node.relationships.field_image?.field_media_image.alt ?? '';
  const promoted = node.sticky;

  return {
    title,
    type: 'article_card',
    name: '',
    drupal_id,
    url,
    summary,
    imageComponent: image ? (
      <GatsbyImage
        alt={imageAlt}
        image={image}
        imgStyle={{
          objectFit: 'cover',
        }}
        objectPosition={getFocalPoint(imageField)}
      />
    ) : (
      <StaticImage
        aspectRatio={1.5}
        src="../assets/media/images/cat-dog-article-placeholder-2.jpg"
        alt="A cute dog & a cute cat."
      />
    ),
    promoted,
  };
};

const getArticleMedia = (node: IArticleContent, mediaType: string) => {
  let media = null;
  switch (mediaType) {
    case 'media__remote_video':
    case 'media__infographic':
      media = {
        media: node.relationships.field_media,
      };
      break;
    default:
      media = {
        media: node.relationships.field_image,
        sizes:
          '(min-width: 960px) 960px, (min-width: 1200px) 1140px, (min-width: 1400px) 1320px, calc(100vw - 24px)',
      };
      break;
  }

  return getMedia(media);
};

interface Tag {
  href: string;
  label: string;
}

function getTagLinks(tags: any[]): Tag[] {
  return tags
    .map((tag: any) => {
      const pageDelegatePath = tag.relationships?.field_page_delegate?.path?.alias ?? null;
      if (!pageDelegatePath) {
        return null;
      }

      return {
        href: pageDelegatePath,
        label: tag?.name ?? '',
      };
    })
    .filter(tag => tag !== null) as Tag[];
}

const ArticleTemplate: React.FC<PageProps<DataProps>> = function ({
  data: { node, relatedArticles, backfillArticles, translations },
}) {
  const { t } = useTranslation();
  const { field_media, field_tags = [] } = node.relationships;
  const tags = getTagLinks(field_tags);
  const body = node.body?.processed ?? '';
  const mediaType: string = field_media?.internal.type || 'media__image';
  const attachedMedia = getArticleMedia(node, mediaType);
  const helmetProps = getHelmetPropsFromMetatagProps(node.metatag);
  const schemaMarkup = getJsonFromMetatagProps(node.metatag);
  let { edges: related } = relatedArticles;
  const { edges: backfill } = backfillArticles;
  if (related.length < 3) {
    related = [...related, ...backfill].slice(0, 3);
  }
  return (
    <Layout
      node={node}
      metaData={node.metatag}
      language={node.langcode}
      translations={translations}
    >
      <Seo {...helmetProps} schemaMarkup={schemaMarkup} />
      <AnalyticsPoint type="module" label="Article title section" typeLabel="article_title_section">
        <Container className="my-5">
          <Row className="justify-content-md-center text-center mb-5">
            <Col md="8">
              <div className="mb-4">
                {tags.map((tag: any, index: number) => (
                  <AnalyticsPoint
                    key={`tag-${index}`}
                    type="component"
                    label="article category"
                    typeLabel="article_category"
                  >
                    <Link className={chip} to={tag?.href || '/'}>
                      <span className="visually-hidden">{t('Read articles about:')}</span>
                      {tag.label}
                    </Link>
                  </AnalyticsPoint>
                ))}
              </div>
              <h1 className="h1 fw-light">{node.title}</h1>
            </Col>
          </Row>
        </Container>
      </AnalyticsPoint>
      <Container fluid="lg" className="mb-5 text-center">
        {attachedMedia}
      </Container>
      <Container fluid>
        <Row className="justify-content-md-center">
          <Col lg="8">
            <RichText isArticle body={body} />
          </Col>
        </Row>
      </Container>
      {related.length === 3 && (
        <AnalyticsPoint
          type="module"
          typeLabel="article_related_articles"
          label="related articles"
          as={Container}
          fluid
          style={{ background: '#F3F7FF', paddingBlock: '7rem' }}
        >
          <Container>
            <h2 className="h3 fw-light mb-5">{t('Related articles')}</h2>
            <div className={relatedLayout}>
              {related.map(({ node: articleNode }: { node: IArticleContent }, index: number) => {
                return (
                  <div key={`${index}-${articleNode.id}`}>
                    <ArticleOverlayCard
                      {...getArticleCardProps(articleNode)}
                      condensed={index !== 0}
                    />
                  </div>
                );
              })}
            </div>
          </Container>
        </AnalyticsPoint>
      )}
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!, $language: String!, $nid: Int!, $species: Int, $tags: [Int]) {
    node: nodeArticle(id: { eq: $id }, langcode: { eq: $language }) {
      ...ArticlePage
    }
    translations: allNodeArticle(
      filter: { drupal_internal__nid: { eq: $nid }, langcode: { ne: $language } }
    ) {
      edges {
        node {
          langcode
          path {
            alias
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    relatedArticles: allNodeArticle(
      limit: 3
      sort: [{ sticky: DESC }, { created: DESC }]
      filter: {
        field_tags: { elemMatch: { drupal_internal__target_id: { in: $tags } } }
        id: { ne: $id }
        langcode: { eq: $language }
      }
    ) {
      edges {
        node {
          ...ArticleCard
        }
      }
    }
    backfillArticles: allNodeArticle(
      limit: 3
      filter: {
        field_species_term: { drupal_internal__target_id: { eq: $species } }
        id: { ne: $id }
        langcode: { eq: $language }
      }
    ) {
      edges {
        node {
          ...ArticleCard
        }
      }
    }
  }
`;

export default ArticleTemplate;
