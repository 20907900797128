import React, { useContext } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SearchListingContext } from './SearchListingContext';

interface SortingProps {
  serverData?: DrupalSearch;
  options: { value: string; label: string }[];
  initialValue?: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const Sorting: React.FC<SortingProps> = function ({ serverData, options, initialValue, onChange }) {
  const context = useContext(SearchListingContext);
  const { t } = useTranslation();
  const sort_title = t('Sort By');

  if (!context && !serverData) {
    throw new Error(
      'Child components of SearchListing cannot be rendered outside the SearchListing component!',
    );
  }

  return (
    <Form>
      <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
        <Form.Label column sm="12" className="text-nowrap">
          {sort_title}
        </Form.Label>
        <Col sm="12">
          <Form.Select
            size="sm"
            value={initialValue || ''}
            aria-label={t('Sort By')}
            id="sort"
            onChange={onChange}
          >
            {options.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Form.Group>
    </Form>
  );
};

Sorting.displayName = 'SearchListing.Sorting';

export default Sorting;
