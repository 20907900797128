import { cx } from '@linaria/core';
import { styled } from '@linaria/react';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import React from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import ButtonDiadWrapper from 'src/components/button/ButtonDiadWrapper';
import { CMS_THEME } from '../common/enums';
import { ButtonLink } from '../components/button/Button';
import MediaRemoteVideo from '../components/media/MediaRemoteVideo';
import MediaVhallVideo from '../components/media/MediaVhallVideo';
import RichText from '../components/rich_text/RichText';
import { theme } from '../theme/theme';

type Props = {
  node: IStorageVideo;
};

const VideoContainer = styled(Container)<{ cmsTheme: CMS_THEME }>`
  background-color: ${({ cmsTheme }) => theme[cmsTheme].background.default};
  color: ${({ cmsTheme }) => theme[cmsTheme].text.default};
`;

const StorageVideo = ({ node: video }: Props) => {
  const cmsTheme = video?.behaviors?.entity_variant?.theme || CMS_THEME.LIGHT;
  const contentFirst = video?.behaviors?.entity_variant?.content_first ?? false;
  const orientation = video?.behaviors?.entity_variant?.orientation || 'vertical';
  const HeadingElement = video?.behaviors?.entity_heading?.element || 'h2';
  const headingStyle = video?.behaviors?.entity_heading?.style || 'typography_h2';
  const hasLinks = video?.links && video.links.length > 0;
  const videoMedia = video.relationships.video[0];

  const contentBlock = (
    <>
      {!!video?.title && (
        <HeadingElement className={cx(headingStyle)}>{video.title}</HeadingElement>
      )}
      {!!video?.body?.processed && (
        <RichText
          cmsTheme={cmsTheme}
          className={cx('mt-4, is-video')}
          body={video.body.processed}
        />
      )}
      {hasLinks && (
        <ButtonDiadWrapper>
          {video.links?.map((link, index) => (
            <AnalyticsPoint
              type="component"
              node={{ name: link.title, title: link.title, type: 'video_link', drupal_id: '' }}
              as={ButtonLink}
              key={index}
              cmsTheme={cmsTheme}
              to={link.url}
              variant={index === 0 ? 'btn' : 'btn-outline'}
              {...link.options?.attributes}
            >
              {link.title}
            </AnalyticsPoint>
          ))}
        </ButtonDiadWrapper>
      )}
    </>
  );

  const horizontalOrientation = (
    <Container>
      <Row className={cx(contentFirst && 'flex-row-reverse')}>
        <Col>
          <Stack gap={2}>
            {videoMedia.field_media_oembed_video && (
              <MediaRemoteVideo
                cmsTheme={cmsTheme}
                field_media_oembed_video={videoMedia.field_media_oembed_video}
                field_media_video_transcript={videoMedia.field_media_video_transcript || ''}
                id={video.id}
                internal={videoMedia.internal}
              />
            )}
            {videoMedia.field_media_video_embed_field && (
              <MediaVhallVideo
                cmsTheme={cmsTheme}
                field_media_video_embed_field={videoMedia.field_media_video_embed_field}
                field_media_video_transcript={videoMedia.field_media_video_transcript || ''}
                id={video.id}
                internal={videoMedia.internal}
              />
            )}
          </Stack>
          {!!video?.additionalText?.processed && (
            <RichText
              cmsTheme={cmsTheme}
              className={cx('mt-4')}
              body={video?.additionalText?.processed}
            />
          )}
        </Col>
        <Col
          xs={12}
          md={6}
          className="card-content d-md-flex flex-column justify-content-center ps-lg-5"
        >
          {contentBlock}
        </Col>
      </Row>
    </Container>
  );

  const verticalOrientation = (
    <Stack className="py-5 text-center container-xxl" gap={5}>
      <Stack
        gap={3}
        className={cx('align-items-center', `${contentFirst ? 'order-2' : 'order-0'}`)}
      >
        {contentBlock}
      </Stack>
      {videoMedia.field_media_oembed_video && (
        <MediaRemoteVideo
          cmsTheme={cmsTheme}
          field_media_oembed_video={videoMedia.field_media_oembed_video}
          field_media_video_transcript={videoMedia.field_media_video_transcript || ''}
          id={video.id}
          internal={videoMedia.internal}
        />
      )}
      {videoMedia.field_media_video_embed_field && (
        <MediaVhallVideo
          cmsTheme={cmsTheme}
          field_media_video_embed_field={videoMedia.field_media_video_embed_field}
          field_media_video_transcript={videoMedia.field_media_video_transcript || ''}
          id={video.id}
          internal={videoMedia.internal}
        />
      )}
    </Stack>
  );

  return (
    <VideoContainer
      data-id={video.drupal_id}
      className="py-3 py-lg-5 px-0 "
      fluid
      cmsTheme={cmsTheme}
    >
      {orientation === 'vertical' ? verticalOrientation : horizontalOrientation}
    </VideoContainer>
  );
};

export default StorageVideo;
