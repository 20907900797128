import { styled } from '@linaria/react';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { CMS_THEME } from '../../common/enums';
import { theme } from '../../theme/theme';

const ResponsiveVideo = styled.div`
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;

  iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

const TranscriptBar = styled.div<{ cmsTheme: CMS_THEME }>`
  text-align: center;

  a {
    text-transform: capitalize;
    text-decoration: none;
    color: ${({ cmsTheme }) => theme[cmsTheme].action};
    &:hover {
      color: ${({ cmsTheme }) => theme[cmsTheme].action};
    }
  }
`;

export interface Props {
  embedUrl: string;
  transcript: string;
  cmsTheme?: CMS_THEME;
}

const VideoEmbed = function ({
  embedUrl,
  transcript,
  cmsTheme = CMS_THEME.LIGHT,
}: Props): React.ReactElement | null {
  const [activeTranscript, setActiveTranscript] = useState(false);
  const url = new URL(embedUrl);
  const urlParams = new URLSearchParams(url.search);
  const embedID = urlParams.get('v');

  const toggleTranscript = () => {
    setActiveTranscript(!activeTranscript);
  };

  if (!embedID) {
    console.warn('Invalid remote video url provided.');
    return null;
  }

  return (
    <>
      <ResponsiveVideo className="order-1">
        <iframe
          width="920"
          height="510"
          src={`https://www.youtube-nocookie.com/embed/${embedID}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title={embedID}
          style={{ borderRadius: '1rem' }}
        />
      </ResponsiveVideo>
      {transcript && (
        <TranscriptBar cmsTheme={cmsTheme} className="order-1">
          <a href="#video-transcript" onClick={toggleTranscript}>
            View video transcript
          </a>
        </TranscriptBar>
      )}
      {transcript && (
        <Modal
          show={activeTranscript}
          size="lg"
          fullscreen="sm-down"
          scrollable
          onHide={toggleTranscript}
        >
          <Modal.Header closeButton>
            <Modal.Title>Video Transcript</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* eslint-disable-next-line react/no-danger */}
            <p className="typography_body" dangerouslySetInnerHTML={{ __html: transcript }} />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default VideoEmbed;
