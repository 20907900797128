import { styled } from '@linaria/react';
import React from 'react';
import { Accordion, Stack } from 'react-bootstrap';
import { CMS_THEME } from '../common/enums';
import FaqItem from '../components/faq/FaqItem';
import RichText from '../components/rich_text/RichText';
import Typography from '../components/typography/Typography';
import { ComponentColor, componentColors } from '../theme/componentColors';

type Props = {
  node: IStorageFaqSection;
};

const defaultSettings = {
  entity_variant: {
    theme: CMS_THEME.LIGHT,
  },
};

const AccordionWrapper = styled(Stack)<{ componentColor: ComponentColor }>`
  .accordion-button {
    color: ${({ componentColor }) => componentColor.color};
    background-color: ${({ componentColor }) => componentColor.background};
  }

  .accordion-body {
    color: ${({ componentColor }) => componentColor.color};
    background-color: #fff;
  }
`;

const StorageFaqSection = ({ node }: Props) => {
  const { qa_sets: faqList } = node.relationships;
  const settings = Object.assign(defaultSettings, node.behaviors || {});
  const cmsTheme = settings.entity_variant.theme;
  const componentColor = componentColors[cmsTheme] || componentColors.light;
  const renderQASets = (faqItem: IStorageFaq) => (
    <FaqItem componentColor={componentColor} node={faqItem} key={faqItem.id} />
  );

  // If all the accordion sets are draft, don't render.
  if (!faqList || faqList.length === 0) {
    return null;
  }

  return (
    <AccordionWrapper data-id={node.drupal_id} componentColor={componentColor}>
      <Stack className="gap-4 container-xxl mb-3">
        <div className={(node?.title || node?.body?.processed) && 'mt-5'}>
          {node?.title && (
            <Typography className="no-print" variant="h2">
              {node.title}
            </Typography>
          )}
          {node?.body?.processed && <RichText body={node.body.processed} />}
        </div>
        <Accordion defaultActiveKey={node.relationships.qa_sets[0].id} id={node.id} flush>
          {faqList.map(renderQASets)}

          {/* for print reasons we need to display all the content without accordions */}
          <div className="print-only">
            {faqList.map(faq => (
              <>
                <Typography variant="h3">{faq.question}</Typography>
                <RichText body={faq.answer.processed} className="mb-4" />
              </>
            ))}
          </div>
        </Accordion>
      </Stack>
    </AccordionWrapper>
  );
};

export default StorageFaqSection;
