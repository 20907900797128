// TODO: fix dependency cycle, Language, setShowMenu, and LyticsSignUp / Buttonlink issues

import { cx } from '@linaria/core';
import { styled } from '@linaria/react';
import { mdiChevronDown } from '@mdi/js';
import Icon from '@mdi/react';
import { GatsbyImage } from 'gatsby-plugin-image';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import * as React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import Nav from 'react-bootstrap/Nav';
import NavLink from 'react-bootstrap/NavLink';
import Row from 'react-bootstrap/Row';
import { CMS_THEME } from 'src/common/enums';
import { defaultColors } from '../../theme/colors';
import { theme } from '../../theme/theme';
import { getHtmlAttributes } from '../../utils/paragraphHelpers';
import LanguageSwitcher from '../LanguageSwitcher';
import Link from '../Link';
import LinkListItem from '../LinkListItem';
import LyticsSignUp from '../LyticsSignUp';
import { ButtonLink } from '../button/Button';
import RichText from '../rich_text/RichText';
import { ParagraphProps as ParagraphMenu } from './ParagraphMenu';

const StyledLink = styled(Link)`
  font-size: 0.625rem;
  letter-spacing: 2px;
`;

const StyledNavLink = styled(NavLink)`
  @media screen and (max-width: 991px) {
    color: ${defaultColors.primary} !important;
    font-size: 1.375rem !important;

    & > span {
      color: ${defaultColors.light} !important;
      background-color: ${defaultColors.footerBg} !important;
    }
  }

  @media screen and (min-width: ${theme.media.lg}) {
    color: inherit !important;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const StyledNav = styled.div<{ offset?: number }>`
  a {
    color: ${defaultColors.dark};
  }
  .dropdown {
    border-bottom: 1px solid ${defaultColors.border};

    .dropdown-toggle {
      display: flex;
      justify-content: space-between;
      font-size: 24px;
      color: ${defaultColors.dark};
      font-size: 1.375rem;

      @media screen and (min-width: ${theme.media.lg}) and (max-width: 1199px) {
        font-size: 1.2rem;
      }

      .icon {
        height: 36px;
        width: 36px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: ${defaultColors.light};

        svg {
          width: 24px;
          height: 24px;
        }
      }

      ::after {
        display: none;

        svg {
          display: inline-block;
          height: 1.75rem;
          width: 1.75rem;
        }
      }
    }

    &.show {
      border: none;
      & > a svg {
        transform: rotate(180deg);
      }
    }
  }

  .dropdown-menu {
    border: none;
  }

  @media screen and (min-width: ${theme.media.lg}) {
    .dropdown {
      border-bottom: none;
      position: static;

      .dropdown-toggle {
        justify-content: start;

        .icon {
          height: auto;
          width: auto;
          display: block;
          background-color: transparent;
        }

        svg {
          height: 1.125rem;
          width: 1.125rem;
          margin-left: 0.25rem;
        }
      }
    }

    .dropdown-menu {
      background: none;
      position: fixed !important;
      top: ${props => (props?.offset ? `${props.offset}px` : 'auto')};
      left: 0 !important;
      right: 0 !important;
      border-top-left-radius: unset;
      border-top-right-radius: unset;
      margin: 0;
      padding: 0;
      .mega-content {
        max-height: 80vh;
        overflow-y: auto;
        border-bottom-left-radius: 0.375rem;
        border-bottom-right-radius: 0.375rem;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        > *:last-child {
          border-bottom-right-radius: 0.375rem;
        }
      }

      &.show {
        .mega-content {
          border-top: 1px solid ${defaultColors.border} !important;
        }
      }
    }
  }
`;

const NavTitleWithNoSubMenu = styled(Link)`
  font-size: 1.375rem;
  padding: 8px;
  text-decoration: none;
  font-weight: 300;

  @media screen and (min-width: ${theme.media.lg}) and (max-width: 1199px) {
    font-size: 1.2rem;
  }

  @media screen and (max-width: 991px) {
    font-size: 1.375rem !important;
    color: ${defaultColors.primary} !important;
  }

  &:hover {
    text-decoration: underline;
  }
`;
const SubNavLinks = styled(Link)`
  text-decoration: none;

  &:hover {
    color: ${defaultColors.primary} !important;
    ::before {
      content: '|';
      display: inline-block;
    }
  }
`;
const NavSubtitle = styled.span`
  color: ${defaultColors.dark};
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 2.5px;
  text-transform: uppercase;
`;
const SubMenuNav = styled(Nav)`
  background: ${defaultColors.subNavBg};
  list-style: none;
  padding: 0;
`;

const StyledLinkWith = styled(Link)`
  color: ${defaultColors.primary} !important;

  &:hover {
    text-decoration: underline !important;
  }
`;

const StyledMediaCol = styled(Col)`
  background-color: ${defaultColors.subNavBg};
`;

const StyledSubNavRow = styled(Row)`
  padding-bottom: 1rem;

  @media screen and (min-width: ${theme.media.lg}) {
    & > :nth-last-child(-n + 3):not(:nth-child(-n + 4)) {
      margin-top: -60px;
    }
    & > :last-child {
      margin-bottom: 2rem;
    }
  }

  .overview {
    font-size: 1.2rem;
    padding-left: 12px !important;
    margin-bottom: 8px;
  }
`;

const StyledTopMenuLinks = styled.div`
  background: ${defaultColors.primary};
  color: #fff;
  display: flex;
  justify-content: center;

  li {
    display: flex;
    padding: 8px;
    width: 50%;
  }

  li:first-child {
    border-right: 2px solid #fff;
  }

  a {
    color: #fff;
    font-size: 20px;
    line-height: 34px;
    margin: auto;
    padding: 8px;
    text-decoration: none;
  }

  @media screen and (max-width: 400px) {
    a {
      font-size: 16px;
    }
  }
`;

interface MegaMenuProps {
  node: IStorageMegaMenu;
  offset?: number | undefined;
  className?: string;
  topMenuLinks: ParagraphMenu;
  translations?: Translations;
  showMenu: boolean;
  setShowMenu: Function;
}

const MegaMenu: React.FC<MegaMenuProps> = function ({
  node,
  offset,
  className,
  topMenuLinks,
  showMenu,
  setShowMenu,
  translations,
}) {
  const menuLinks = node.relationships?.menu_links;
  const menuSecondary = node.relationships?.secondary_menu;
  const colonPartition = (length: number) => {
    if (length <= 1) return 'col-12';
    if (length === 2) return 'col-12 col-lg-6';
    return 'col-12 col-md-6 col-lg-3';
  };

  return (
    <AnalyticsPoint
      type="module"
      typeLabel="mega_menu"
      label="Mega menu"
      as={StyledNav}
      id="mainMenu"
      offset={offset}
      className={cx(className, 'overflow-auto')}
    >
      <Nav
        as="ul"
        aria-label="Main Navigation"
        className="flex-column flex-lg-row w-100 p-lg-0 gap-3 gap-lg-1 gap-xl-3"
      >
        <StyledTopMenuLinks className="mb-2 d-lg-none">
          {topMenuLinks.menu.map(link => {
            return <LinkListItem link={link} key={link.title} setShowMenu={setShowMenu} />;
          })}
        </StyledTopMenuLinks>
        <div className="d-lg-none">
          <LanguageSwitcher language={node.langcode} translations={translations} />
        </div>

        {menuLinks?.map(menuLink => {
          const subMenus = menuLink?.relationships?.menus || [];
          const card = menuLink.relationships.promo && menuLink.relationships.promo[0];
          const media = card?.relationships.images && card.relationships.images[0];

          if (subMenus.length > 0) {
            return (
              <Dropdown
                id={`nav-${menuLink.id}`}
                as="li"
                key={menuLink.id}
                className="nav-item px-3 px-lg-0"
              >
                {menuLink?.link?.title && (
                  <Dropdown.Toggle className="fw-light" as={StyledNavLink}>
                    {menuLink.link.title}
                    <span className="icon rounded-circle">
                      <Icon path={mdiChevronDown} />
                    </span>
                  </Dropdown.Toggle>
                )}

                <Dropdown.Menu renderOnMount className="border-0">
                  <Container fluid className="p-0">
                    <Container fluid="lg">
                      <Row className="gx-lg-0 bg-white mega-content">
                        <Col lg={card ? 8 : 12} className="p-3 px-lg-5">
                          {menuLink?.link?.url && menuLink?.link?.title && (
                            <StyledLinkWith
                              className="h4 py-3 mb-lg-3 d-none d-lg-inline-block text-decoration-none text-body"
                              to={menuLink.link.url}
                            >
                              {menuLink.link.title}
                            </StyledLinkWith>
                          )}
                          <StyledSubNavRow>
                            {showMenu &&
                              (menuLink?.link?.url || '').includes('science-of-nutrition') && (
                                <AnalyticsPoint
                                  type="component"
                                  node={{
                                    name: menuLink.link.title,
                                    type: 'sub_menu_item',
                                    id: '',
                                  }}
                                  as="li"
                                  className="px-3 px-lg-0 overview"
                                >
                                  <SubNavLinks
                                    to={menuLink.link.url}
                                    className="text-body py-1 d-block fw-light"
                                  >
                                    {menuLink.link.title}
                                  </SubNavLinks>
                                </AnalyticsPoint>
                              )}

                            {subMenus.map(subMenu => {
                              const { links } = subMenu;
                              return (
                                <AnalyticsPoint
                                  type="component"
                                  node={{
                                    id: subMenu.id,
                                    type: 'submenu_header_item',
                                    name: subMenu.link?.title || '',
                                  }}
                                  as={Col}
                                  key={subMenu.id}
                                  className={`${colonPartition(subMenus.length)} mb-3`}
                                >
                                  {links.length >= 1 && (
                                    <>
                                      <NavSubtitle>{subMenu.link?.title}</NavSubtitle>
                                      <SubMenuNav
                                        as="ul"
                                        className="flex-column rounded-2 border rounded-2 p-2 mt-1"
                                      >
                                        {links.map((link, index) => {
                                          return (
                                            <AnalyticsPoint
                                              type="component"
                                              node={{
                                                name: link.title,
                                                type: 'sub_menu_item',
                                                id: '',
                                              }}
                                              as="li"
                                              key={`${subMenu.id}${index + link.title}`}
                                              className="px-3 px-lg-0"
                                            >
                                              <SubNavLinks
                                                to={link.url}
                                                {...link.options?.attributes}
                                                className="text-body py-1 d-block fw-light"
                                              >
                                                {link.title}
                                              </SubNavLinks>
                                            </AnalyticsPoint>
                                          );
                                        })}
                                      </SubMenuNav>
                                    </>
                                  )}
                                </AnalyticsPoint>
                              );
                            })}
                          </StyledSubNavRow>
                        </Col>
                        {media && (
                          <StyledMediaCol className="pe-5 ps-3 py-3  d-none d-lg-block">
                            <div>
                              <GatsbyImage
                                alt=""
                                image={media.relationships.field_media_image?.gatsbyImage}
                                className="rounded-2 mb-3"
                              />
                              {card.body && <RichText body={card.body.processed} />}
                              {card.links &&
                                card.links.map(link => {
                                  const isSignUpLink = link.options?.attributes?.sign_up;
                                  return (
                                    <AnalyticsPoint
                                      type="component"
                                      key={`promo-link-${link.title}`}
                                      node={{
                                        name: link.title,
                                        type: 'submenu_item',
                                        id: '',
                                      }}
                                      className="px-3 px-lg-0"
                                    >
                                      {isSignUpLink ? (
                                        <LyticsSignUp>
                                          <ButtonLink
                                            {...link?.options?.attributes}
                                            variant="btn"
                                            cmsTheme={CMS_THEME.LIGHT}
                                            className="mt-3"
                                          >
                                            {link.title}
                                          </ButtonLink>
                                        </LyticsSignUp>
                                      ) : (
                                        <ButtonLink
                                          {...link?.options?.attributes}
                                          variant="btn"
                                          to={link.url}
                                          cmsTheme={CMS_THEME.LIGHT}
                                          className="mt-3"
                                        >
                                          {link.title}
                                        </ButtonLink>
                                      )}
                                    </AnalyticsPoint>
                                  );
                                })}
                            </div>
                          </StyledMediaCol>
                        )}
                      </Row>
                    </Container>
                  </Container>
                </Dropdown.Menu>
              </Dropdown>
            );
          }
          return (
            <AnalyticsPoint
              key={menuLink.id}
              type="component"
              node={{ name: menuLink.link.title, type: 'submenu_item', id: '' }}
              style={{ display: 'flex' }}
              className="px-3 px-lg-0"
            >
              <NavTitleWithNoSubMenu
                to={menuLink.link.url}
                key={menuLink.id}
                {...getHtmlAttributes(menuLink.link.options?.attributes)}
              >
                {menuLink.link.title}
              </NavTitleWithNoSubMenu>
            </AnalyticsPoint>
          );
        })}
      </Nav>
      {menuSecondary && (
        <ul className="d-block d-lg-none px-3 list-unstyled" style={{ columnCount: '2' }}>
          {menuSecondary.links.map((link, index) => {
            return (
              <AnalyticsPoint
                type="component"
                node={{ name: link.title, type: 'secondary_menu_item', id: '' }}
                as="li"
                key={`secondary-${index + link.title}`}
                className="px-3 px-lg-0"
              >
                <StyledLink
                  to={link.url}
                  {...link.options?.attributes}
                  className="text-body fs-xx-small d-block text-uppercase text-decoration-none py-2"
                >
                  {link.title}
                </StyledLink>
              </AnalyticsPoint>
            );
          })}
        </ul>
      )}
    </AnalyticsPoint>
  );
};

export default MegaMenu;
