import { styled } from '@linaria/react';
import { mdiWeb } from '@mdi/js';
import Icon from '@mdi/react';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownItem from 'react-bootstrap/DropdownItem';
import { CMS_THEME } from '../common/enums';
import { theme } from '../theme/theme';

interface Props {
  language: Languages;
  translations?: Translations;
}

type LanguagesLabel = {
  [key: string]: string;
};

const languagesLabel: LanguagesLabel = {
  en: 'English',
  ja: '日本語',
  es: 'Español',
  fr: 'Français',
  'zh-hans': '中文',
  it: 'Italiano',
  'pt-br': 'Português',
  de: 'Deutsch',
};

const DropdownToggleContainer = styled.div<{ cmsTheme: CMS_THEME }>`
  .dropdown-toggle {
    background: #03202f;
    border: none;
    color: #fff !important;
    font-family: 'Antenna';
    font-size: 1rem !important;
    font-weight: 400;
    letter-spacing: 2.5px;
    padding: 0.25rem 0.5rem;
    text-transform: uppercase;
    width: 320px;
    margin: auto;

    :hover {
      background-color: #03202f;
      border: none;
    }

    ::after {
      display: inline-block !important;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: '';
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
    }
  }

  @media (min-width: ${theme.media.lg}) {
    .dropdown-toggle {
      width: auto;
    }
  }
`;

const DropdownItemsContainer = styled.div<{ cmsTheme: CMS_THEME }>`
  background-color: ${({ cmsTheme }) => theme[cmsTheme].background.default};
  color: ${({ cmsTheme }) => theme[cmsTheme].text.default};

  @media (min-width: ${theme.media.lg}) {
    width: 280px;
  }
`;

const LanguageSwitcher: React.FC<Props> = ({ language = 'en' as Languages, translations }) => {
  const { i18n } = useTranslation();
  // @ts-ignore
  const activeLanguage = i18n.language;
  const location = useLocation();

  const cmsTheme = CMS_THEME.CREAM;
  const defaultLabel = languagesLabel[activeLanguage];

  const handleSelect = (eventKey: string | null) => {
    const langcodeFragmentRegex = /^\/(ja|es|fr|zh-hans|it|pt-br|de)\/?/;
    const updatedPathFragment = location.pathname.replace(langcodeFragmentRegex, '/');
    let targetPath = eventKey === '/' ? `${location.origin}${updatedPathFragment}` : `${eventKey}`;
    targetPath = targetPath.replace('/index', '/');
    navigate(targetPath);
  };
  if (translations?.edges.length && language) {
    return (
      <Dropdown onSelect={handleSelect} align="end">
        <DropdownToggleContainer className="mb-3 mb-lg-0" cmsTheme={cmsTheme}>
          <Dropdown.Toggle size="sm" variant="light" className="rounded-2">
            <Icon path={mdiWeb} size={0.9} className="me-2" /> {defaultLabel}
          </Dropdown.Toggle>
        </DropdownToggleContainer>
        <Dropdown.Menu>
          <DropdownItemsContainer className="m-lg-3 py-3 rounded-2" cmsTheme={cmsTheme}>
            {translations.edges.map(lang => (
              <DropdownItem
                key={lang.node.path.alias}
                eventKey={lang.node.path.alias}
                className="py-2"
              >
                {languagesLabel[lang.node.langcode]}
              </DropdownItem>
            ))}
          </DropdownItemsContainer>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
  return null;
};

export default LanguageSwitcher;
