import { css, cx } from '@linaria/core';
import { styled } from '@linaria/react';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import debounce from 'lodash/debounce';
import React, { RefObject, useEffect, useRef, useState } from 'react';
import { Card, Stack } from 'react-bootstrap';
import ChevronLeftIcon from 'src/assets/icons/chevron-left';
import ChevronRightIcon from 'src/assets/icons/chevron-right';
import RichText from 'src/components/rich_text/RichText';
import { CMS_THEME } from '../common/enums';
import { ButtonLink } from '../components/button/Button';
import GatsbyMediaImage from '../components/media/MediaImage';
import Typography from '../components/typography/Typography';
import { theme } from '../theme/theme';

const StyledGrid = styled.div<{ columns: string; cmsTheme: CMS_THEME }>`
  --grid-layout-gap: 1rem;
  --grid-column-count: ${props => props.columns};
  --grid-item--min-width: 250px;

  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
  --grid-item--max-width: calc((100% - var(--total-gap-width)) / var(--grid-column-count));

  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr)
  );
  color: ${({ cmsTheme }) => theme[cmsTheme].text.default};
  column-gap: var(--grid-layout-gap);
  row-gap: 70px;
  display: grid;
  place-items: center;
`;

const StyledHorizontalVariant = styled.div<{ columns: string; cmsTheme: CMS_THEME }>`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  margin: 0 54px;

  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  > div {
    flex-basis: 100%;
    flex-shrink: 0;
    margin-bottom: 32px;
    max-width: 400px;
    padding-left: 8px;
    padding-right: 8px;

    @media (min-width: ${theme.media.md}) {
      flex-basis: 50%;
      padding-left: 0;
      padding-right: 40px;
    }

    @media (min-width: ${theme.media.lg}) {
      flex-basis: ${props => 100 / Number(props.columns)}%;
    }
  }

  .scrollBtn {
    position: absolute;
    top: 40%;
    background: #fff;
    border-radius: 100%;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.3);
    height: 48px;
    width: 48px;
    border: 0;
    padding: 6px;

    & svg {
      height: 36px;
      width: 36px;
    }
  }

  .scrollBtn-left {
    left: 0;
  }

  .scrollBtn-right {
    right: 0;
  }
`;

const StyledStack = styled(Stack)<{ cmsTheme: CMS_THEME }>`
  background-color: ${({ cmsTheme }) => theme[cmsTheme].background.default};
  color: ${({ cmsTheme }) => theme[cmsTheme].text.default};
`;

const StyledCard = styled(Card)<{ cmsTheme: CMS_THEME }>`
  background-color: ${({ cmsTheme }) => theme[cmsTheme].background.default};
  color: ${({ cmsTheme }) => theme[cmsTheme].text.default};
`;

const gridClass = css`
  padding-left: 1rem;
  @media (min-width: 450px) {
    padding-right: 1rem;
  }
`;

const defaultSettings = {
  entity_heading: {
    element: 'h2',
    style: 'typography_h2',
  },
  entity_subheading: {
    element: 'h3',
    style: 'typography_h3',
  },
  entity_buttons: {
    primary_button: 'btn',
  },
  entity_variant: {
    text_align: 'left',
    theme: CMS_THEME.LIGHT,
  },
  entity_rows_columns: {
    columns: '2',
    slider: '0',
  },
};

type ContentCardItemProps = IStorageContentCard & {
  mode?: string;
  buttonVariant: 'btn' | 'btn-outline' | 'btn-link';
  columns: number;
  cmsTheme: CMS_THEME;
  indexOfComponent: number;
  indexOfModule: number;
};

const ContentCardItem = (el: ContentCardItemProps): JSX.Element => {
  const headingStyle = el.columns < 4 ? 'h3' : 'h4';

  return (
    <AnalyticsPoint
      as={Stack}
      data-id={el.drupal_id}
      className="flex-column"
      gap={3}
      type="component"
      node={el}
    >
      <StyledCard cmsTheme={el.cmsTheme} className="border-0 d-flex rounded-3">
        {el.relationships?.field_media[0]?.relationships &&
          (el.relationships.field_media[0] as MediaImage).relationships.field_media_image && (
            <GatsbyMediaImage
              {...(el.relationships.field_media[0] as MediaImage)}
              variant={el.imgSize}
            />
          )}
      </StyledCard>
      <Typography variant={headingStyle} className="fw-light">
        {el.title}
      </Typography>
      {/* eslint-disable-next-line react/no-danger */}
      {el.copy && <RichText body={el.copy?.processed} />}
      {el?.link?.title && (
        <div className="mt-auto">
          <ButtonLink
            variant={el.buttonVariant}
            to={el.link.url}
            cmsTheme={el.cmsTheme}
            {...el.link.options?.attributes}
          >
            {el.link.title}
          </ButtonLink>
        </div>
      )}
    </AnalyticsPoint>
  );
};

type StorageCardGridProps = {
  node: IStorageCardGrid;
};

const StorageCardGrid = ({ node: cardGrid }: StorageCardGridProps) => {
  const settings = Object.assign(defaultSettings, cardGrid?.behaviors || {});
  const themeType = cardGrid.relationships.theme?.field_color_type ?? 'light';
  const cmsTheme = settings.entity_variant.theme;
  const { primary_button: buttonStyle } = settings.entity_buttons;
  const { text_align: textAlign } = settings.entity_variant;
  const { columns, slider } = settings.entity_rows_columns;
  const { element: headingElement, style: headingStyle } = settings.entity_heading;
  const { element: subheadingElement, style: subheadingStyle } = settings.entity_subheading;
  const {
    relationships: { cards },
  } = cardGrid;

  const renderCardItem = (element: IStorageContentCard, i: number) => (
    <ContentCardItem
      indexOfModule={cardGrid.indexOnPage}
      indexOfComponent={i}
      {...element}
      cmsTheme={cmsTheme}
      columns={Number(columns)}
      buttonVariant={buttonStyle}
      key={element.id}
      mode={themeType}
    />
  );

  const horizontalContainerRef = useRef<HTMLElement>(null);
  const [displayLeft, setDisplayLeft] = useState(false);
  const [displayRight, setDisplayRight] = useState(true);

  const pixelPaddingAmount = 54;
  const scrollBuffer = 10;

  useEffect(() => {
    const handleScroll = debounce((e: any) => {
      const { scrollLeft } = e.target;

      setDisplayLeft(scrollLeft > scrollBuffer || false);
      setDisplayRight(
        scrollLeft + e.target.offsetWidth < e.target.scrollWidth - pixelPaddingAmount || false,
      );
    }, 200);

    if (horizontalContainerRef?.current) {
      const currentRef = horizontalContainerRef.current;
      currentRef.addEventListener('scroll', handleScroll);

      return () => {
        currentRef.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  function triggerHorizontalScroll(direction: string = 'right') {
    if (horizontalContainerRef?.current) {
      let { scrollLeft } = horizontalContainerRef.current;
      scrollLeft = Math.floor(scrollLeft);

      const cardWidth = horizontalContainerRef?.current.children[0].offsetWidth;

      let scrollAmount = 0;

      if (scrollLeft % cardWidth < 10) {
        scrollAmount = cardWidth;
      } else {
        scrollAmount =
          direction === 'right' ? cardWidth - (scrollLeft % cardWidth) : scrollLeft % cardWidth;
      }

      if (direction === 'right') {
        horizontalContainerRef.current.scrollBy({
          left: scrollAmount,
          behavior: 'smooth',
        });
        scrollLeft += scrollAmount;
      } else {
        horizontalContainerRef.current.scrollBy({
          left: -scrollAmount,
          behavior: 'smooth',
        });
        scrollLeft -= scrollAmount;
      }

      setDisplayLeft(scrollLeft > scrollBuffer || false);
      setDisplayRight(
        scrollLeft + horizontalContainerRef.current.offsetWidth <
          horizontalContainerRef.current.scrollWidth - pixelPaddingAmount || false,
      );
    }
  }

  // If cards are all drafts, render nothing.
  if (!cards || cards.length === 0) {
    return null;
  }

  return (
    <StyledStack data-id={cardGrid.drupal_id} cmsTheme={cmsTheme}>
      <Stack className={cx('py-2 py-lg-5 gap-2 gap-lg-5 container-xxl text-center', gridClass)}>
        <div>
          {cardGrid?.title && (
            <Typography key="card-title" variant={headingElement} className={headingStyle}>
              {cardGrid?.title}
            </Typography>
          )}
          {cardGrid?.subtitle && (
            <Typography key="card-subtitle" variant={subheadingElement} className={subheadingStyle}>
              {cardGrid?.subtitle}
            </Typography>
          )}
          {cardGrid.body?.processed && (
            <RichText
              key="card-content"
              // eslint-disable-next-line react/no-danger
              body={cardGrid.body?.processed}
            />
          )}
        </div>
        <div className="gap-5 d-flex flex-column position-relative">
          {slider === '1' ? (
            <StyledHorizontalVariant
              columns={columns}
              cmsTheme={cmsTheme}
              className={`text-${textAlign}`}
              ref={horizontalContainerRef as RefObject<HTMLDivElement>}
            >
              {cards.map(renderCardItem)}

              {displayLeft && (
                <button
                  className="scrollBtn scrollBtn-left"
                  onClick={() => triggerHorizontalScroll('left')}
                  type="button"
                  aria-label="Scroll Left"
                >
                  <ChevronLeftIcon />
                </button>
              )}

              {displayRight && (
                <button
                  className="scrollBtn scrollBtn-right"
                  onClick={() => triggerHorizontalScroll()}
                  type="button"
                  aria-label="Scroll Right"
                >
                  <ChevronRightIcon />
                </button>
              )}
            </StyledHorizontalVariant>
          ) : (
            <StyledGrid
              columns={columns}
              key="card-grid-desktop"
              cmsTheme={cmsTheme}
              className={`text-${textAlign}`}
            >
              {cards.map(renderCardItem)}
            </StyledGrid>
          )}
        </div>
      </Stack>
    </StyledStack>
  );
};

export default StorageCardGrid;
