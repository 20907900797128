interface ColorProps {
  primary: string;
  primaryDark: string;
  dark: string;
  darker: string;
  footerBg: string;
  utilityBg: string;
  subNavBg: string;
  secondary: string;
  light: string;
  border: string;
}

export const themes: Record<string, ColorProps> = {
  purina: {
    primary: '#e91c24',
    primaryDark: '#B72025',
    dark: '#2B2B2B',
    footerBg: '#51626f',
    subNavBg: '#c3d3e5',
    utilityBg: '#1e1e1e',
    darker: '#212121',
    secondary: '#a9a390',
    light: '#EDF0F8',
    border: '#E6E6E6',
  },
};

export const defaultColors = themes.purina;
