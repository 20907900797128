import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import { css, cx } from '@linaria/core';
import { styled } from '@linaria/react';
import * as React from 'react';
import Container from 'react-bootstrap/Container';
import { getFocalPoint } from 'src/utils/mediaHelpers';
import { CMS_OVERLAY, CMS_THEME } from '../common/enums';
import ArticleOverlayCard from '../components/article_card/ArticleOverlayCard';
import RichText from '../components/rich_text/RichText';
import { theme } from '../theme/theme';

const ArticleCardsContainer = styled(Container)<{ cmsTheme: CMS_THEME }>`
  background-color: ${({ cmsTheme }) => theme[cmsTheme].background.default};
  color: ${({ cmsTheme }) => theme[cmsTheme].text.default};

  img {
    max-width: 100%;
  }
`;

const articlesGrid = css`
  display: grid;
  gap: 1rem;
  grid-template-columns: 100%;
  grid-template-rows: auto;

  @media (min-width: ${theme.media.md}) {
    grid-template-columns: 1fr 1fr;

    & > :first-child {
      grid-column: 1 / 3;
      grid-row: 1;
    }
  }

  @media (min-width: 960px) {
    grid-template-columns: 66% 1fr;
    grid-template-rows: auto;
    grid-auto-flow: column;

    & > :first-child {
      grid-column: 1;
      grid-row: 1 / 3;

      & > * {
        height: 100%;
      }
    }
  }
`;

interface StorageManualCardsProps {
  node: IStorageManualCards;
}

const getOverlayCardProps = (card: IStorageManualCard) => {
  const { title, type, name, drupal_id } = card;
  const summary = card.summary ?? undefined;
  const url = card.link?.url;
  const media = card.relationships.images[0];
  const image = media.relationships.field_media_image?.gatsbyImage;
  const imageAlt = media.field_media_image?.alt ?? '';

  return {
    drupal_id,
    title,
    url,
    summary,
    type,
    name,
    imageComponent: image ? (
      <GatsbyImage
        alt={imageAlt}
        image={image}
        imgStyle={{
          objectFit: 'cover',
        }}
        objectPosition={getFocalPoint(card.relationships.image)}
      />
    ) : (
      <StaticImage
        aspectRatio={1.5}
        src="../assets/media/images/cat-dog-article-placeholder-2.jpg"
        alt="A cute dog & a cute cat."
      />
    ),
    promoted: false,
  };
};

const DefaultSettings = {
  entity_heading: {
    element: 'h2',
    style: 'typography_h2',
  },
  entity_variant: {
    theme: CMS_THEME.LIGHT,
    overlay: CMS_OVERLAY.LIGHT,
  },
};
const StorageManualCards: React.FC<StorageManualCardsProps> = function ({ node: manualCards }) {
  const settings = Object.assign(DefaultSettings, manualCards?.behaviors || {});
  const cmsTheme = settings.entity_variant.theme;
  const cmsOverlay = settings.entity_variant.overlay;
  const { element: HeadingElement, style: headingStyle } = settings.entity_heading;

  // If all the cards are draft, don't render.
  if (!manualCards?.relationships?.cards || manualCards.relationships.cards.length === 0) {
    return null;
  }

  return (
    <ArticleCardsContainer
      data-id={manualCards.drupal_id}
      className="py-3 py-lg-5"
      fluid
      cmsTheme={cmsTheme}
    >
      <Container
        className={cx(manualCards?.title || manualCards?.body?.processed ? 'pb-3 pb-lg-5' : '')}
      >
        {manualCards?.title && (
          <HeadingElement className={cx(headingStyle)}>{manualCards.title}</HeadingElement>
        )}
        {manualCards?.body?.processed && (
          <RichText cmsTheme={cmsTheme} className={cx('mt-3')} body={manualCards.body.processed} />
        )}
      </Container>
      <Container>
        <div className={cx(manualCards.relationships.cards.length === 3 ? articlesGrid : 'row')}>
          {manualCards.relationships.cards.map((card, index) => {
            return (
              <ArticleOverlayCard
                {...getOverlayCardProps(card)}
                cmsTheme={cmsTheme}
                cmsOverlay={cmsOverlay}
                condensed={index !== 0 && manualCards.relationships.cards.length > 2}
              />
            );
          })}
        </div>
      </Container>
    </ArticleCardsContainer>
  );
};

export default StorageManualCards;
