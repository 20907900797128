import { AxiosError } from 'axios';
import { PageProps, graphql, navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ParagraphSearchForm from 'src/components/paragraphs/ParagraphSearchForm';
import RichText from 'src/components/rich_text/RichText';
import { getCentreSquareSearchData } from 'src/ssr/getSearchData';
import { handleFacetChange, handleSortChange } from 'src/utils/helpers';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import SearchListing from '../../components/search_listing/SearchListing';
import StickyFacetContainer from '../../components/search_listing/StickyFacetContainer';
import { getCmsComponent } from '../../utils/cmsComponentHelpers';
import {
  getHelmetPropsFromMetatagProps,
  getJsonFromMetatagProps,
} from '../../utils/metatagHelpers';
import { ResourceSD } from './types/types';

interface DataProps {
  node: IResourceListing;
  translations: Translations;
}

const ResourceListingTemplate: React.FC<PageProps<DataProps, any, any, ResourceSD>> = function ({
  data: { node, translations },
  location,
  serverData,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    setIsLoading(false);
  }, [location]);

  if (!node) return null;

  const { components, subnav, related } = node.relationships;
  const helmetProps = getHelmetPropsFromMetatagProps(node.metatag);
  const schemaMarkup = getJsonFromMetatagProps(node.metatag);

  const params = new URLSearchParams(location.search);
  const sortBy = params.get('sort_by');

  const sortOptions = [
    {
      label: t('Relevance'),
      value: 'relevance',
    },
    {
      label: t('Latest'),
      value: 'latest',
    },
    {
      label: t('Read/Watch Time'),
      value: 'read_watch_time',
    },
    {
      label: t('Alphabetical'),
      value: 'title',
    },
  ];
  return (
    <Layout
      node={node}
      metaData={node.metatag}
      language={node.langcode}
      translations={translations}
    >
      <Seo {...helmetProps} schemaMarkup={schemaMarkup} />
      {subnav && getCmsComponent({ node: subnav, index: 0 })}
      <Container fluid className="bg-gray-100 py-5 text-center">
        {!node.hide_title && <h1>{node.title}</h1>}
        {node?.body?.processed && (
          <RichText className="text-center py-4" body={node.body.processed} />
        )}

        {components?.map((component, index) => {
          return (
            <React.Fragment key={component.id}>
              {getCmsComponent({ node: component, index })}
            </React.Fragment>
          );
        })}
        <Container className="mt-5">
          <div className="mx-lg-5">
            <ParagraphSearchForm
              props={{
                showSearch: true,
                showMenu: false,
                showCancel: false,
                setShowSearch: () => true,
                selectedSearchCategory: 'centresquare',
                is_listing_page: true,
              }}
            />
          </div>
        </Container>
      </Container>
      <Container className="my-5">
        <SearchListing
          data={serverData.resourceData || undefined}
          error={serverData.error || new Error(t('Sorry, there was an error'))}
          loaded={!isLoading}
          defaultLoadedState
        >
          <Row className="align-items-center py-3">
            <SearchListing.SearchHeader
              title={t('Resources')}
              onFacetRemove={e => handleFacetChange(e, location, navigate)}
            />
          </Row>
          <Row className="position-relative">
            <Col lg={3} className="py-3 d-none d-lg-block">
              <StickyFacetContainer>
                <SearchListing.Sorting
                  options={sortOptions}
                  initialValue={sortBy}
                  onChange={e => handleSortChange(e, location, navigate)}
                />
                <SearchListing.SearchFacets
                  onChange={e => handleFacetChange(e, location, navigate)}
                />
              </StickyFacetContainer>
            </Col>
            <Col>
              <SearchListing.ResourceSearchResults serverData={serverData.resourceData} />
              <SearchListing.Pagination serverData={serverData.resourceData} />
            </Col>
            <SearchListing.FacetModal onChange={e => handleFacetChange(e, location, navigate)} />
          </Row>
        </SearchListing>
      </Container>
    </Layout>
  );
};

export const getServerData = async (props: any) => {
  try {
    const queries = {
      ...props.query,
    };
    const drupalResponse = await getCentreSquareSearchData(props.pageContext, { ...queries });
    return {
      status: 200,
      headers: {
        'Cache-Control': 'public, max-age=3600, s-maxage=3600, stale-while-revalidate=3600',
      },
      props: {
        resourceData: drupalResponse.data,
        contextualFilters: drupalResponse.contextualFilters,
        error: null,
      },
    };
  } catch (e) {
    return {
      status: 500,
      props: {
        error: (e as AxiosError).response,
        resourceData: null,
      },
    };
  }
};

export const query = graphql`
  query ($id: String!, $nid: Int!, $language: String!) {
    node: nodeResourceListingPage(id: { eq: $id }, langcode: { eq: $language }) {
      internal {
        type
      }
      id
      title
      hide_title: field_hide_title
      path {
        alias
      }
      body {
        processed
      }
      langcode
      ...Metatag
      relationships {
        species: field_species_term {
          drupal_internal__tid
        }
        read_watch_time: field_read_watch_time {
          drupal_internal__tid
        }
        type: field_type {
          drupal_internal__tid
        }
        bucket: field_resource_bucket {
          drupal_internal__tid
        }
        category: field_resource_category {
          drupal_internal__tid
        }
        components: field_storage {
          type: __typename
          ...StorageArticleCards
          ...StorageImageContent
          ...StorageHeroCard
          ...StorageManualCards
          ...StorageHighlights
          ...StorageRichText
          ...StoragePageTitle
          ...StorageBanner
          ...StorageBrandGrid
          ...StorageFaqSection
          ...StorageUnfilteredHtml
          ...StorageSubnav
          ...StorageCardGrid
          ...StorageCategoryCards
          ...StorageContactCards
          ...StorageVideo
        }
      }
    }
    translations: allNodeResourceListingPage(
      filter: { drupal_internal__nid: { eq: $nid }, langcode: { ne: $language } }
    ) {
      edges {
        node {
          langcode
          path {
            alias
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default ResourceListingTemplate;
