import { styled } from '@linaria/react';
import * as React from 'react';
import LinkListItem from '../LinkListItem';

const StyledMenu = styled.ul`
  list-style: none;
  margin-top: 0.5rem;

  a,
  div {
    display: inline-flex;
    text-decoration: none;
    line-height: 1rem;
    letter-spacing: 1.5px;
  }

  a:hover {
    text-decoration: underline;
  }
`;

export interface ParagraphProps extends Paragraph {
  link: LinkProps;
  menu: [LinkProps];
}

interface ParagraphMenuProps {
  node: ParagraphProps;
  props?: {
    location?: string;
  };
}

const ParagraphMenu: React.FC<DeepReadonly<ParagraphMenuProps>> = function ({ node, props }) {
  const { menu, behavior_settings, link: headerLink } = node;
  const classes = behavior_settings?.decoupled_styles?.classes || [];
  const allClasses = [...classes, 'menu'];

  return (
    <StyledMenu className={allClasses.join(' ')} node={node}>
      {headerLink && <LinkListItem link={headerLink} />}
      {menu.map((link, index) => {
        return (
          <LinkListItem
            link={link}
            key={link.title}
            border={props?.location === 'footer' && index !== menu.length - 1}
            center={props?.location === 'footer'}
          />
        );
      })}
    </StyledMenu>
  );
};

export default ParagraphMenu;
