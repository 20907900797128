/* eslint-disable react/no-danger */
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import { css, cx } from '@linaria/core';
import { styled } from '@linaria/react';
import React from 'react';
import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';
import { useTranslation } from 'react-i18next';
import { CMS_THEME } from '../common/enums';
import Link from '../components/Link';
import { Button } from '../components/button/Button';
import Filmstrip from '../components/filmstrip/Filmstrip';
import RichText from '../components/rich_text/RichText';
import Typography from '../components/typography/Typography';
import { theme } from '../theme/theme';
import { getMedia } from '../utils/mediaHelpers';

const StyledGrid = styled.div<{ cmsTheme: CMS_THEME }>`
  color: ${({ cmsTheme }) => theme[cmsTheme].text.default};
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, minmax(10px, 1fr));
  place-items: center;

  a {
    color: ${({ cmsTheme }) => theme[cmsTheme].text.default};
    text-decoration: none;
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, minmax(16px, 1fr));
  }
`;

const StyledCard = styled(Card)<{ cmsTheme: CMS_THEME }>`
  background-color: ${({ cmsTheme }) => theme[cmsTheme].background.paper};
  color: ${({ cmsTheme }) => theme[cmsTheme].text.default};
  height: 226px;
  padding: ${theme.common.spacing[1]};
`;

const gridClass = css`
  padding-left: 1rem;

  @media (min-width: 450px) {
    padding-right: 1rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  button {
    width: 100% !important;
  }
`;

const StyledLink = styled(Link)`
  justify-content: center;
  flex-direction: column;
  display: flex;

  span {
    margin-top: 20px;
  }
`;

const StyledFilmStrip = styled(Filmstrip)<{ cmsTheme: CMS_THEME }>`
  a {
    color: ${({ cmsTheme }) => theme[cmsTheme].text.default};
    text-decoration: none;
  }
`;

const BrandCardItem = (
  el: IStorageBrandCard & { mode?: string; cmsTheme: CMS_THEME }
): JSX.Element => {
  return (
    <AnalyticsPoint
      as={Stack}
      type="component"
      node={el}
      data-id={el.drupal_id}
      style={{
        minWidth: '15rem',
        maxWidth: '19.5rem',
        color: theme[el.cmsTheme].text.default,
      }}
      className="d-grid w-100"
      gap={3}
    >
      <StyledLink to={el.link.url} {...el.link.options?.attributes}>
        <StyledCard
          cmsTheme={el.cmsTheme}
          className="d-flex w-100 justify-content-center border-0 align-items-center rounded-3"
        >
          {el.relationships?.field_media[0] &&
            getMedia({
              media: el.relationships?.field_media[0],
              variant: 'w400',
              objectFit: 'contain',
              style: { width: '100%', height: '100%' },
            })}
        </StyledCard>
        <Typography className="text-center fs-5 fw-light">{el.text}</Typography>
      </StyledLink>
    </AnalyticsPoint>
  );
};

type Props = {
  node: IStorageBrandGrid;
};

const defaultSettings = {
  entity_heading: {
    element: 'h2',
    style: 'typography_h2',
  },
  entity_variant: {
    text_align: 'left',
    load_more: false,
    theme: CMS_THEME.DARK,
  },
};

const StorageBrandGrid = ({ node: brandGrid }: Props) => {
  const breakpoint = useBreakpoint();
  const [showAll, setShowAll] = React.useState(false);
  const settings = Object.assign(defaultSettings, brandGrid?.behaviors || {});
  const cmsTheme: CMS_THEME = settings.entity_variant.theme;
  const { element: HeadingElement, style: headingStyle } = settings.entity_heading;
  const textAlign = settings.entity_variant.text_align;
  const {
    relationships: { cards },
  } = brandGrid;
  const { t } = useTranslation();

  const condensed = settings.entity_variant.load_more && cards.length > 4;
  const isMobile = breakpoint.xs;
  const renderCardItem = (element: IStorageBrandCard) => (
    <BrandCardItem {...element} cmsTheme={cmsTheme} key={element.id} mode={cmsTheme} />
  );
  const firstFourBrandElements = condensed ? cards.slice(0, 4) : cards;
  const restBrandElements = condensed && showAll ? cards.slice(4) : [];

  // If all cards are draft, render nothing.
  if (!cards || cards.length === 0) {
    return null;
  }

  return (
    <Stack
      data-id={brandGrid.drupal_id}
      style={{
        backgroundColor: theme[cmsTheme].background.default,
        color: theme[cmsTheme].text.default,
      }}
    >
      <Stack className={cx('py-5 gap-5 container-xxl', gridClass, `text-${textAlign}`)}>
        <div>
          {brandGrid?.title && (
            <HeadingElement className={cx(headingStyle)}>{brandGrid.title}</HeadingElement>
          )}
          {brandGrid?.body?.processed && (
            <RichText cmsTheme={cmsTheme} className={cx('mt-4')} body={brandGrid.body.processed} />
          )}
        </div>
        {!isMobile && (
          <div className="d-flex flex-column gap-5">
            <StyledGrid cmsTheme={cmsTheme} key="brand-grid-desktop">
              {[...firstFourBrandElements, ...restBrandElements].map(renderCardItem)}
            </StyledGrid>
            {condensed && (
              <ButtonContainer>
                <Button
                  cmsTheme={cmsTheme}
                  shape="square"
                  className="text-capitalize align-self-center justify-content-center text-center"
                  style={{ maxWidth: 453 }}
                  onClick={() => setShowAll(prevState => !prevState)}
                >
                  {showAll ? t('view less') : t('view all')}
                </Button>
              </ButtonContainer>
            )}
          </div>
        )}
        {isMobile && (
          <div key="brand-grid-mobile" role="region" aria-label="Subcategories" className="d-flex">
            <StyledFilmStrip cmsTheme={cmsTheme} className="fs-no-offset">
              {cards.map(renderCardItem)}
            </StyledFilmStrip>
          </div>
        )}
      </Stack>
    </Stack>
  );
};

export default StorageBrandGrid;
