import { styled } from '@linaria/react';
import { sendIt } from 'gatsby-plugin-purina-analytics/common/functions';
import * as React from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { SubmitHandler } from 'react-hook-form';
import { CMS_THEME } from '../common/enums';
import {
  Calories,
  calculateCalories,
} from '../components/feeding_calculator/FeedingCalculatorCalories';
import FeedingCalculatorForm, {
  FormValues,
} from '../components/feeding_calculator/FeedingCalculatorForm';
import FeedingCalculatorResults from '../components/feeding_calculator/FeedingCalculatorResults';
import RichText from '../components/rich_text/RichText';
import { theme } from '../theme/theme';

interface FeedingCalculatorProps {
  node: IStorageFeedingCalculator;
}

const FeedingCalculatorContainer = styled(Container)<{ cmsTheme: CMS_THEME }>`
  background-color: ${({ cmsTheme }) => theme[cmsTheme].background.default};
  color: ${({ cmsTheme }) => theme[cmsTheme].text.default};

  p {
    font-size: 16px;
    font-weight: 200;
    margin-bottom: 0;
  }

  label {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }

  input,
  select {
    background-color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 16px;

    &::placeholder {
      font-size: 16px;
      font-weight: 200;
      color: gray;
    }
  }

  .form-check label {
    font-size: 18px;
    font-weight: 400;
    letter-spacing: inherit;
    text-transform: none;
  }

  input[type='radio'] {
    accent-color: #e91c24;
    border: 1px solid #c4c4c4;
    font-size: 16px;
  }

  .form-check-input:checked {
    background-color: #e91c24;
    border-color: #e91c24;
  }

  .form-select {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='14' height='10' fill='none'%3e%3cpath stroke='%23E91C24' stroke-width='1.5' d='m1 1 6 7 6-7'/%3e%3c/svg%3e");
  }

  .link {
    color: #e91c24;
    font-weight: 500;
  }

  h2 {
    text-transform: uppercase;
  }

  .resultsText {
    max-width: 280px;
    margin: auto;
    text-transform: uppercase;

    & h4 {
      color: #e91c24;
      font-weight: 700;
    }

    & h6 {
      font-weight: 700;
    }
  }

  .ageDescription {
    h5,
    p,
    li {
      font-size: 14px;
      font-weight: 200;
      margin: 0 0 4px 0;
    }

    ul {
      margin: 0 4px;
    }

    li {
      margin: 0;
    }
  }

  .footnote p {
    font-size: 14px;
  }

  form .required > .form-label:before,
  .requiredFieldLabel:before {
    content: ' *';
    color: #e91c24;
    padding-right: 4px;
  }

  form .required > .form-label:after {
    content: '';
  }
`;

const StorageFeedingCalculator = ({ node }: FeedingCalculatorProps) => {
  const storage = node;
  const cmsTheme = CMS_THEME.CREAM;

  const [calories, setCalories] = React.useState<Calories | undefined>(undefined);
  const [weightGoal, setWeightGoal] = React.useState<'maintain' | 'lose'>('maintain');

  // TODO: work with BE to solve null media issue
  // const media = storage.relationships?.media;

  const resultsRef = React.useRef<HTMLElement>(null);

  const onSubmit: SubmitHandler<FormValues> = data => {
    const caloriesCalc = calculateCalories(
      storage.species,
      data.age,
      data.weight,
      data.units,
      data.bcs,
    );
    if (caloriesCalc) {
      sendIt({
        event: 'pdp_feeding_calculator',
        eventCategory: 'product details',
        eventAction: 'feeding calculator form',
        eventLabel: 'calculate results',
        eventParams: {
          form_name: 'pdp feeding calculator',
          form_type: 'feeding recommendation',
          pet_weight: String(data.weight),
          units: data.units,
          age_group: data.age,
          body_condition_score: String(data.bcs),
        },
      });
      setCalories(caloriesCalc);
    }

    resultsRef.current?.scrollIntoView();
  };

  return (
    <FeedingCalculatorContainer fluid cmsTheme={cmsTheme} className="py-5">
      <Container>
        <Row className="gx-5">
          <Stack gap={2} className="mb-3">
            <h2 className="h3">{storage.title}</h2>
            <RichText cmsTheme={cmsTheme} body={storage.summary_text.processed} />

            <p className="requiredFieldLabel">{storage.required_fields_label}</p>
          </Stack>

          <Col md={6}>
            <FeedingCalculatorForm
              node={storage}
              submitCallback={onSubmit}
              updateWeightGoal={setWeightGoal}
            />
          </Col>

          <Col md={6} className="mt-1 mt-sm-0">
            <div ref={resultsRef}>
              {calories && weightGoal && (
                <>
                  <FeedingCalculatorResults
                    node={storage}
                    weightGoalValue={weightGoal}
                    calories={calories}
                  />
                  <RichText
                    cmsTheme={cmsTheme}
                    className="mt-2 footnote"
                    body={storage.results_text.processed}
                  />
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </FeedingCalculatorContainer>
  );
};

export default StorageFeedingCalculator;
