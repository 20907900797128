import { styled } from '@linaria/react';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { CMS_THEME } from '../common/enums';
import RichText from '../components/rich_text/RichText';
import { theme } from '../theme/theme';

type Props = {
  node: IStorageRichText;
};

const Wrapper = styled(Container)<{ cmsTheme: CMS_THEME }>`
  background-color: ${({ cmsTheme }) => theme[cmsTheme].background.default};
  color: ${({ cmsTheme }) => theme[cmsTheme].text.default};

  @media print {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;

    & > .container {
      max-width: 100% !important;
    }
  }
`;

const DefaultSettings = {
  entity_variant: {
    theme: CMS_THEME.LIGHT,
  },
};

const StorageRichText = function ({ node: richText, props }: Props) {
  const settings = Object.assign(DefaultSettings, richText?.behaviors || {});
  const body = richText.body?.processed || '';
  const cmsTheme = settings.entity_variant.theme;

  // Quick solution for print styles requirments to prevent rich text display before subnavs
  let noPrint = '';
  if (props?.components) {
    const followingComponent = props.components.find(
      comp => comp.indexOnPage === richText.indexOnPage + 1,
    );

    if (followingComponent?.type === 'storage__subnav') {
      noPrint = 'no-print';
    }
  }

  return (
    <Wrapper
      data-id={richText.drupal_id}
      className={`py-3 py-lg-5 px-0 ${noPrint}`}
      fluid
      cmsTheme={cmsTheme}
    >
      <Container>
        <Row className="justify-content-md-center">
          <Col>
            <RichText body={body} cmsTheme={cmsTheme} />
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};

export default StorageRichText;
